import { localStorageApi } from 'shared/api'
import { ACCEPT_COOKIES_KEY, BREAKPOINTS } from 'shared/config'
import { Box, Button, Stack, Typography } from '@mui/material'
import { theme } from 'shared/theme'
import { useTranslate } from 'shared/i18n'
import { useState } from 'react'
import { useIsBreakpoint } from 'shared/hooks'

export const AcceptCookies = () => {
	const { translate } = useTranslate()
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)
	const cookiesAccepted = localStorageApi.getValue(ACCEPT_COOKIES_KEY)

	const [isHide, setIsHide] = useState<boolean>(
		cookiesAccepted === null ? false : !!+cookiesAccepted
	)

	const text = `
<p>Продолжая использовать сайт, Вы даете <span style="white-space: nowrap">ООО «ГИС БИС»</span> <span style="white-space: nowrap">(ИНН 7814785520),</span> <a target="_blank" href="https://gisbis.ru/politics.html">согласие на обработку файлов cookies и пользовательских данных</a>.<br/>Если Вы не хотите, чтобы Ваши данные обрабатывались, просим отключить обработку файлов cookies или покинуть сайт.</p>
`

	const handleAccepted = () => {
		try {
			localStorageApi.setValue(ACCEPT_COOKIES_KEY, '1')
			setIsHide(true)
		} catch (e) {
			setIsHide(false)
			console.log(e)
		}
	}

	if (isHide) {
		return null
	}

	return (
		<Box
			sx={{
				position: 'fixed',
				left: 0,
				right: 0,
				bottom: 0,
				top: isMobile ? 0 : 'auto',
				p: isMobile ? 1.5 : 0.5,
				bgcolor: '#00000095',
				zIndex: 99999,
				backdropFilter: 'blur(5px)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Stack
				spacing={isMobile ? 1 : 3}
				alignItems="center"
				direction={isMobile ? 'column' : 'row'}
				justifyContent={isMobile ? 'center' : 'space-around'}
				sx={{ width: '100%', maxWidth: '1280px' }}
				mx="auto"
			>
				<Typography
					variant="body2"
					color={theme.palette.grey['100']}
					dangerouslySetInnerHTML={{ __html: text }}
					textAlign={isMobile ? 'center' : 'left'}
				/>

				<Button
					variant="contained"
					onClick={handleAccepted}
					sx={{
						boxShadow: 'none',
						width: '100%',
						maxWidth: isMobile ? '100%' : '180px',
					}}
				>
					{translate('Accept')}
				</Button>
			</Stack>
		</Box>
	)
}
