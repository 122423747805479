import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { theme } from 'shared/theme'
import { useAppDispatch, useAppSelector } from 'shared/model'

import { selectSelectors } from 'entities/select'

import { mapActions, mapSelectors } from 'widgets/map/model'
import type { IStatRecord } from 'widgets/map/model'
import { useMapContext } from 'widgets/map/context'
import { getSelectSysGeom } from 'widgets/map/model/thunk'
import { useUser } from 'entities/user'

const PLOSHADKA_ID = 907
const EXCLUDE_PLOSHADKA_USERNAME = ['odmoshushary']

export const GlobalStat = () => {
	const dispatch = useAppDispatch()

	const { map, dataGlobalInfoSource, defaultZoom, defaultExtent } =
		useMapContext()

	const { nameUser } = useUser() || {}

	const idActiveLayerList = useAppSelector(mapSelectors.selectActiveIdLayerList)
	const mapOnLoadEnd = useAppSelector(mapSelectors.selectMapOnLoadEnd)
	const globalStatData = useAppSelector(mapSelectors.selectGlobalStat)

	const selectListRow = useAppSelector(selectSelectors.selectFlatSelectListRow)

	const statIdSelect = useAppSelector((state) => state.map.statIdSelect)
	const selectFlatSelectListRow = useAppSelector(
		selectSelectors.selectFlatSelectListRow
	)

	const filteredGlobalStat = useMemo(() => {
		if (!nameUser || !EXCLUDE_PLOSHADKA_USERNAME.includes(nameUser)) {
			return globalStatData
		}

		return globalStatData.filter((i) => i.idSelect !== +PLOSHADKA_ID)
	}, [globalStatData, nameUser])

	const handleOnRecordClick = async (record: IStatRecord) => {
		dataGlobalInfoSource.clear()

		setDefaultMapView()

		setActiveLayer(record.idSelect)

		dispatch(mapActions.setStatIdSelect(record.idSelect))
		dispatch(mapActions.setLeftSidebarContentType('sys-data'))
		dispatch(mapActions.setIsOpenLeftSidebar(true))

		await dispatchSysGeom(record.idSelect)
	}

	const dispatchSysGeom = async (idSelect: number) => {
		const idLayer = idSelect
			? selectListRow.find((i) => +i.id === +idSelect)?.idLayer
			: undefined

		if (!idLayer || !idSelect) {
			return
		}

		try {
			await dispatch(
				getSelectSysGeom({ idSelect: +idSelect, idLayer: +idLayer })
			)
		} catch (e) {
			console.log(e)
		}
	}

	const setDefaultMapView = () => {
		if (!map || (!defaultZoom && !defaultExtent)) {
			return
		}

		const currentCenter = map.getView().getCenter()
		const currentZoom = map.getView().getZoom()

		if (currentZoom === defaultZoom) {
			return
		}

		if (defaultExtent) {
			map.getView().fit(defaultExtent, {
				duration: 300,
			})
			return
		}

		if (defaultZoom) {
			map.getView().animate({
				center: currentCenter,
				zoom: defaultZoom,
				duration: 300,
			})
			return
		}
	}

	const setActiveLayer = useCallback(
		(idSelect: number) => {
			const idLayer = selectFlatSelectListRow.find(
				(i) => +i.id === +idSelect
			)?.idLayer

			if (!idLayer) {
				return
			}

			if (!idActiveLayerList.map((i) => +i).includes(+idLayer)) {
				dispatch(
					mapActions.setActiveIdLayerList([...idActiveLayerList, +idLayer])
				)
			}
		},
		[idActiveLayerList, selectFlatSelectListRow]
	)

	const renderRecordRow = useCallback(
		(record: IStatRecord) => {
			if (+record.recordCountSelect === 0) {
				return null
			}

			return (
				<Stack
					direction="row"
					spacing={3}
					alignItems="center"
					key={record.idSelect}
					onClick={() =>
						record.idSelect === statIdSelect || !mapOnLoadEnd
							? undefined
							: handleOnRecordClick(record)
					}
					sx={{
						cursor:
							record.idSelect === statIdSelect || !mapOnLoadEnd
								? 'inherit'
								: 'pointer',
						px: 1,
						py: 0.5,
						bgcolor:
							record.idSelect === statIdSelect
								? theme.palette.action.selected
								: theme.palette.action.hover,
						'&:hover': {
							bgcolor: !mapOnLoadEnd
								? theme.palette.action.hover
								: theme.palette.action.selected,
						},
						'&:first-of-type': {
							borderTopRightRadius: '8px',
							borderTopLeftRadius: '8px',
						},
						'&:last-child': {
							borderBottomRightRadius: '8px',
							borderBottomLeftRadius: '8px',
						},
					}}
				>
					<Box
						sx={{
							height: '80px',
							width: '100px',
							backgroundImage: `url(${window.location.origin}/gisbis/SelectIconGet?Select=${record.idSelect})`,
							backgroundPosition: 'center',
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
						}}
					></Box>

					<Stack>
						<Typography variant="h6" fontWeight={400} sx={{ lineHeight: 1.2 }}>
							{record.nameSelect}
						</Typography>

						<Typography
							variant="body1"
							fontWeight={400}
							color={theme.palette.grey['500']}
						>
							{record.recordCountSelect} шт.
						</Typography>
					</Stack>
				</Stack>
			)
		},
		[statIdSelect, map, defaultZoom, defaultExtent, mapOnLoadEnd]
	)

	if (!filteredGlobalStat.length) {
		return null
	}

	return (
		<Stack spacing={0.5} width="100%">
			{filteredGlobalStat.map((record) => renderRecordRow(record))}
		</Stack>
	)
}
