import { useEffect } from 'react'
import { useMapContext } from 'widgets/map'

import TileLayer from 'ol/layer/Tile'
import { OSM, XYZ } from 'ol/source'
import { useTranslate } from 'shared/i18n'
import { createXYZ } from 'ol/tilegrid'
import { register } from 'ol/proj/proj4.js'
import proj4 from 'proj4'

proj4.defs(
	'EPSG:3395',
	'+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
)

register(proj4)

export const InitCommonBaseLayers = () => {
	const { map } = useMapContext()
	const { translate } = useTranslate()

	useEffect(() => {
		if (!map) {
			return
		}

		/*
		const osm = new TileLayer({
			properties: {
				title: translate('OSM'),
				type: 'base',
				idLayer: 'osm',
				attr: '&copy;&nbsp;<a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			},
			source: new OSM({
				attributions:
					'&copy;&nbsp;<a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			}),
			visible: false,
			maxZoom: 23,
		})
		osm.setZIndex(0)

		*/

		const yandex1 = new TileLayer({
			properties: {
				title: `${translate('Yandex satellite')}`,
				type: 'base',
				isCommonBase: true,
				idLayer: 'yandex1',
				attr: '<span>&copy;&nbsp;<a target="_blank" href="http://maps.yandex.ru">Yandex.Maps</a></span>',
			},
			source: new XYZ({
				crossOrigin: 'anonymous',
				url: 'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.1159.0&x={x}&y={y}&z={z}&lang=ru_RU',
				projection: 'EPSG:3395',
				tileGrid: createXYZ({
					extent: [
						-20037508.342789244, -20037508.342789244, 20037508.342789244,
						20037508.342789244,
					],
				}),
				attributions:
					'<span>&copy;&nbsp;<a target="_blank" href="http://maps.yandex.ru">Yandex.Maps</a></span>',
			}),
			visible: false,
			maxZoom: 23,
		})

		yandex1.setZIndex(0)

		const yandex2 = new TileLayer({
			properties: {
				title: translate('Yandex map'),
				type: 'base',
				isCommonBase: true,
				idLayer: 'yandex2',
				attr: '<span>&copy;&nbsp;<a target="_blank" href="http://maps.yandex.ru">Yandex.Maps</a></span>',
			},
			source: new XYZ({
				crossOrigin: 'anonymous',
				url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=1&lang=ru_RU',
				projection: 'EPSG:3395',
				tileGrid: createXYZ({
					extent: [
						-20037508.342789244, -20037508.342789244, 20037508.342789244,
						20037508.342789244,
					],
				}),
				attributions:
					'<span>&copy;&nbsp;<a target="_blank" href="http://maps.yandex.ru">Yandex.Maps</a></span>',
			}),
			visible: false,
			maxZoom: 23,
		})

		//map.addLayer(osm)
		map.addLayer(yandex1)
		map.addLayer(yandex2)

		return () => {
			//map.removeLayer(osm)
			map.removeLayer(yandex1)
			map.removeLayer(yandex2)
		}
	}, [map])

	return null
}
