import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { mapActions, mapSelectors, useMapContext } from 'widgets/map'
import BaseEvent from 'ol/events/Event'

export const ObserveMapModeType = () => {
	const dispatch = useAppDispatch()

	const mapModeType = useAppSelector(mapSelectors.selectMapModeType)

	const { map } = useMapContext()

	useEffect(() => {
		if (mapModeType === undefined) {
			document.addEventListener('keydown', handleClearGeom)
		}

		if (mapModeType !== undefined) {
			document.removeEventListener('keydown', handleClearGeom)
		}

		return () => {
			document.removeEventListener('keydown', handleClearGeom)
		}
	}, [mapModeType])

	const handleClearGeom = (evt: KeyboardEvent) => {
		evt.stopPropagation()

		if (evt.code === 'Escape') {
			dispatch(mapActions.setInfoMapGeoms(null))
			return
		}
	}

	return null
}
