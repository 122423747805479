import { useCallback, useMemo } from 'react'
import {
	Checkbox,
	Chip,
	FormControlLabel,
	Stack,
	Typography,
} from '@mui/material'

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'

import { theme } from 'shared/theme'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { useTranslate } from 'shared/i18n'

import { mapActions } from 'widgets/map'

import { Layer } from 'ol/layer'
import { Source } from 'ol/source'
import LayerRenderer from 'ol/renderer/Layer'
import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS } from 'shared/config'

interface ILayerSwitcherItemProps {
	layer: Layer<Source, LayerRenderer<any>>
}

export const LayerSwitcherItem: React.FC<ILayerSwitcherItemProps> = ({
	layer,
}) => {
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)
	const dispatch = useAppDispatch()

	const { translate } = useTranslate()

	const currentZoom = useAppSelector((state) => state.map.currentZoom)
	const activeIdLayerList = useAppSelector(
		(state) => state.map.activeIdLayerList
	)

	const idLayer = layer.get('idLayer')
	const title = layer.get('title')
	const minzoom = layer.getMinZoom()
	const maxzoom = layer.getMaxZoom()

	const checked = useMemo(() => {
		return !!activeIdLayerList.find((i) => i === +idLayer)
	}, [idLayer, activeIdLayerList])

	const disabled = useMemo(() => {
		if (currentZoom === undefined) {
			return false
		}

		return currentZoom < minzoom || currentZoom > maxzoom
	}, [currentZoom, minzoom, maxzoom])

	const handleChange = useCallback(() => {
		if (checked) {
			dispatch(
				mapActions.setActiveIdLayerList(
					activeIdLayerList.filter((i) => +i !== +idLayer)
				)
			)
			return
		}

		dispatch(mapActions.setActiveIdLayerList([...activeIdLayerList, +idLayer]))
	}, [checked, activeIdLayerList, idLayer])

	const switchLayerActive = useCallback(() => {
		if (checked) {
			return
		}

		dispatch(mapActions.setActiveIdLayerList([...activeIdLayerList, +idLayer]))
	}, [checked, activeIdLayerList, idLayer])

	const handleSetCrfFilterList = () => {
		switchLayerActive()

		setTimeout(() => {
			dispatch(mapActions.setCrFilterAutocompleteIdLayerFocus(idLayer || null))
			dispatch(mapActions.setIsOpenCRFFilterSearch(true))
		}, 150)
	}

	return (
		<Stack
			direction="row"
			spacing={0.5}
			alignItems="center"
			justifyContent="space-between"
		>
			<FormControlLabel
				control={
					<Checkbox
						size="small"
						checked={checked}
						onChange={handleChange}
						disabled={disabled}
						color="primary"
						sx={{ paddingBottom: '5px', paddingTop: '5px' }}
					/>
				}
				label={
					<Typography
						variant="body1"
						fontSize={14}
						color={disabled ? theme.palette.text.disabled : 'inherit'}
					>
						{title}
					</Typography>
				}
			/>

			{!!layer.get('classifierFilterRules') &&
				(isMobile ? (
					<FilterAltRoundedIcon />
				) : (
					<Chip
						icon={<FilterAltRoundedIcon />}
						label={translate('Filter')}
						onClick={handleSetCrfFilterList}
						disabled={disabled}
						size="small"
						color="primary"
					/>
				))}
		</Stack>
	)
}
