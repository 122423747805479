import ClearIcon from '@mui/icons-material/Clear'

import { useAppDispatch } from 'shared/model'
import { mapActions } from 'widgets/map'
import { BootstrapTooltip, MapIconButton } from 'shared/ui'
import { useTranslate } from 'shared/i18n'
import { IconButton } from '@mui/material'

export const ClearData = () => {
	const { translate } = useTranslate()
	const dispatch = useAppDispatch()

	const handleClick = () => {
		dispatch(mapActions.setMapinfoData(null))
		dispatch(mapActions.setInfoMapGeoms(null))
		dispatch(mapActions.setLeftSidebarContentType('home-screen'))
		dispatch(mapActions.setDrawerContentType('home-screen'))
	}

	return (
		<BootstrapTooltip title={translate('Clear')}>
			<IconButton size="small" onClick={handleClick}>
				<ClearIcon fontSize="small" />
			</IconButton>
		</BootstrapTooltip>
	)
}
