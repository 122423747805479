import { Route, Routes } from 'react-router-dom'
import MapPage from 'pages/map'

export const Routing = () => {
	return (
		<Routes>
			<Route path="/" element={<MapPage />} />
			<Route path="/:userName" element={<MapPage />} />
			<Route
				path="/:userName/:paramsType/:param1/:param2/:param3"
				element={<MapPage />}
			/>
			<Route path="/*" element={<div>Page not found</div>} />
		</Routes>
	)
}
