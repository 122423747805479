import TravelExploreIcon from '@mui/icons-material/TravelExplore'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { MapIconButton } from 'shared/ui'

import { mapActions } from 'widgets/map'
import { selectDrawerData } from 'widgets/map/model/selectors'

export const MobileToggleLeftSidebar: React.FC = () => {
	const dispatch = useAppDispatch()

	const isOpen = useAppSelector((state) => state.map.isOpenDrawer)

	const drawerData = useAppSelector(selectDrawerData)

	const handleOnClick = () => {
		dispatch(mapActions.setIsOpenDrawer(true))

		if (drawerData.contentType !== 'map-data') {
			dispatch(mapActions.setDrawerContentType('home-screen'))
		}
	}

	return (
		<MapIconButton onClick={handleOnClick}>
			<TravelExploreIcon />
		</MapIconButton>
	)
}
