import { useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'

import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS } from 'shared/config'
import { useAppSelector } from 'shared/model'

import { userSelectors } from 'entities/user'
import { MapContextProvider } from 'widgets/map'

import {
	OnSingleClick,
	OnMoveend,
	OnLoadEnd,
	OnResize,
	OnPointerMove,
} from './events'
import { InitLayers } from './init-layers'

import {
	ObserveCrfLayers,
	ObserveActiveIdLayers,
	ObserveCrfValues,
	ObserveInfoMapGeoms,
	ObserveUriParams,
	ObserveChangeBaseLayer,
	ObserveMapModeType,
	ObserveSysListForMap,
	ObserveSysMarkerHovered,
	ObserveContentType,
} from './observe'

import {
	DesktopPageLayout,
	MobilePageLayout,
	MobileControlsLayout,
} from './layouts'

import * as ol from 'ol'
import type { Map } from 'ol'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import { IUserBoundBox } from 'shared/api/user'
import { boundingExtent, Extent } from 'ol/extent'

import { Coordinate } from 'ol/coordinate'

interface IMapWidgetProps {
	coords: number[]
	zoom: number | undefined
	minZoom: number | undefined
	maxZoom: number | undefined
	boundBox: IUserBoundBox | undefined
}

export const MapWidget: React.FC<IMapWidgetProps> = ({
	coords,
	zoom,
	minZoom,
	maxZoom,
	boundBox,
}) => {
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)
	const userLayerList = useAppSelector(userSelectors.selectUserLayerList)

	const [map, setMap] = useState<Map | null>(null)

	const mapRef = useRef<HTMLDivElement>(null)
	const dataGlobalInfoSource = useRef<VectorSource>(new VectorSource())
	const dataInfoSourceRef = useRef<VectorSource>(new VectorSource())
	const dataEditSourceRef = useRef<VectorSource>(new VectorSource())

	const [defaultZoom, setDefaultZoom] = useState<number | undefined>(zoom)
	const [defaultExtent, setDefaultExtent] = useState<Extent | undefined>(
		undefined
	)

	useEffect(() => {
		if (!mapRef.current) {
			return
		}

		if (map) {
			setMap(null)
		}

		const minExtent = boundBox
			? (fromLonLat([
					boundBox.min.Coordinate.lng,
					boundBox.min.Coordinate.lat,
			  ]) as Coordinate)
			: null

		const maxExtent = boundBox
			? (fromLonLat([
					boundBox.max.Coordinate.lng,
					boundBox.max.Coordinate.lat,
			  ]) as Coordinate)
			: null

		const extent: Extent | undefined =
			minExtent === null || maxExtent === null
				? undefined
				: boundingExtent([minExtent, maxExtent])

		const center = fromLonLat(coords)

		const view = new ol.View({
			center,
			zoom,
			minZoom,
			maxZoom: !!maxZoom ? +maxZoom - 1 : maxZoom,
			extent,
			smoothExtentConstraint: true,
			showFullExtent: true,
			constrainResolution: true,
		})

		const mapObject = new ol.Map({
			view: view,
			layers: [],
			controls: [],
			overlays: [],
			pixelRatio: 1,
		})

		mapObject.setTarget(mapRef.current)

		setDefaultExtent(extent)
		setMap(mapObject)

		return () => mapObject.setTarget(undefined)
	}, [isMobile, zoom, coords, minZoom, maxZoom])

	return (
		<>
			<MapContextProvider
				value={{
					map,
					defaultZoom,
					defaultExtent,
					dataInfoSource: dataInfoSourceRef.current,
					dataEditSource: dataEditSourceRef.current,
					dataGlobalInfoSource: dataGlobalInfoSource.current,
				}}
			>
				<InitLayers layerList={userLayerList} />

				<>
					<OnLoadEnd />
					<OnMoveend />
					<OnSingleClick />
					<OnPointerMove />
					<OnResize />
				</>

				<>
					<ObserveActiveIdLayers />
					<ObserveCrfLayers />
					<ObserveCrfValues />
					<ObserveUriParams />
					<ObserveChangeBaseLayer />
					<ObserveInfoMapGeoms />
					<ObserveMapModeType />
					<ObserveSysListForMap />
					<ObserveSysMarkerHovered />
					<ObserveContentType />
				</>

				{isMobile ? (
					<MobilePageLayout>
						<Box
							ref={mapRef}
							sx={{
								height: '100%',
								width: '100%',
								minHeight: 500,
								position: 'relative',
							}}
						/>

						<MobileControlsLayout />
					</MobilePageLayout>
				) : (
					<DesktopPageLayout>
						<Box
							ref={mapRef}
							sx={{
								height: '100dvh',
								width: '100dvw',
								position: 'relative',
								bgcolor: '#e8e0d0',
							}}
						/>
					</DesktopPageLayout>
				)}
			</MapContextProvider>
		</>
	)
}
