import { axiosInstance } from 'shared/api/instance'
import { requestLib } from 'shared/lib'

interface IGetPreviewPageCount {
	docid: string
}

export const getPreviewPageCount = (
	params: IGetPreviewPageCount
): Promise<number> =>
	axiosInstance
		.post('GetDocPreviewPageCount', requestLib.encodePostParams(params))
		.then((response) => response.data.DocPreviewPageCount)
		.then((response) => {
			requestLib.throwResponseError(response)
			return response?.row?.pageCount || 0
		})
		.catch((e) => {
			throw e
		})
