import React from 'react'
import { styled, TextField } from '@mui/material'
import type {
	FilledTextFieldProps,
	OutlinedTextFieldProps,
	StandardTextFieldProps,
} from '@mui/material/TextField/TextField'

const CssTextField = styled(TextField)<{
	bgcolor: string
	borderradius: string
}>(({ theme, bgcolor, borderradius }) => ({
	'& .MuiOutlinedInput-root': {
		backgroundColor: bgcolor,
		borderRadius: borderradius,
		'& > fieldset': { borderColor: 'transparent' },
	},
	'& .MuiOutlinedInput-root:hover': {
		'& > fieldset': {
			borderColor: 'transparent',
		},
	},
	'& .MuiOutlinedInput-root:focused': {
		'& > fieldset': {
			borderColor: 'transparent',
		},
	},
}))

interface IMapTextField {
	bgColor: string
	borderRadius: string
}

type ExtendedMapTextFieldType =
	| IMapTextField
	| StandardTextFieldProps
	| FilledTextFieldProps
	| OutlinedTextFieldProps

export const MapTextField: React.FC<ExtendedMapTextFieldType> = (props) => {
	// @ts-ignore
	const { bgColor, borderRadius, ...params } = props
	return (
		<CssTextField bgcolor={bgColor} borderradius={borderRadius} {...params} />
	)
}
