import { PropsWithChildren, useCallback, useMemo } from 'react'

import { Box, SwipeableDrawerProps } from '@mui/material'

import { FullScreenPageLayout, WithDrawerPageLayout } from 'shared/ui'
import { useAppDispatch, useAppSelector } from 'shared/model'

import { mapSelectors, mapActions } from 'widgets/map'

import { LayerSwitcherWrapper } from '../../../layer-switcher'
import { SymbolsWrapper } from '../../../symbols'
import { Bio } from '../../../bio'
import { MapData } from '../../../map-data'
import { CRFFilterResult, CRFFilterSearch } from '../../../crf-data-filter'
import { MapSearch } from '../../../map-search'

export const MobilePageLayout: React.FC<PropsWithChildren> = ({ children }) => {
	const dispatch = useAppDispatch()

	const { contentType, isOpen } = useAppSelector(mapSelectors.selectDrawerData)

	const mapOnLoadEnd = useAppSelector(mapSelectors.selectMapOnLoadEnd)

	const isOpenGlobalSearchList = useAppSelector(
		mapSelectors.selectIsOpenGlobalSearchList
	)

	const isOpenCRFFilerList = useAppSelector(
		mapSelectors.selectIsOpenCRFFilterList
	)

	const drawerHeight = useMemo(() => {
		return isOpenCRFFilerList || isOpenGlobalSearchList ? '100%' : 'auto'
	}, [isOpenGlobalSearchList, isOpenCRFFilerList])

	const handleClose = () => {
		dispatch(mapActions.setIsOpenDrawer(false))
	}

	const handleOpen = () => {
		dispatch(mapActions.setIsOpenDrawer(true))
	}

	const withGlobalSearch = useMemo(() => {
		return contentType === 'map-data' || contentType === 'home-screen'
	}, [contentType])

	const renderContent = useCallback(() => {
		if (!mapOnLoadEnd) {
			return null
		}

		return (
			<Box
				sx={{
					height: '100%',
					overflowY: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					py: 2,
					rowGap: 1.5,
				}}
			>
				{withGlobalSearch && (
					<Box sx={{ px: 1 }}>
						<MapSearch />
					</Box>
				)}

				<Box
					sx={{
						flexGrow: 1,
						overflowY: 'auto',
						px: 1,
					}}
				>
					{contentType === 'home-screen' && (
						<Box sx={{ py: 2 }}>
							<Bio />
						</Box>
					)}
					{contentType === 'map-data' && <MapData />}
					{contentType === 'symbol-list' && <SymbolsWrapper />}
					{contentType === 'layer-switcher' && <LayerSwitcherWrapper />}
					{contentType === 'crf-filter' && (
						<>
							<Box>
								<CRFFilterSearch />
							</Box>

							<Box sx={{ mt: 1.5 }}>
								<CRFFilterResult />
							</Box>
						</>
					)}
				</Box>
			</Box>
		)
	}, [mapOnLoadEnd, contentType, isOpenGlobalSearchList, withGlobalSearch])

	const drawerProps: SwipeableDrawerProps = {
		open: isOpen,
		anchor: 'bottom',
		onClose: handleClose,
		onOpen: handleOpen,
	}

	return (
		<FullScreenPageLayout>
			<WithDrawerPageLayout
				drawerContent={renderContent()}
				drawerHeight={drawerHeight}
				drawerProps={drawerProps}
			>
				{children}
			</WithDrawerPageLayout>
		</FullScreenPageLayout>
	)
}
