import React, { useEffect, useMemo, useState } from 'react'
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Collapse,
	IconButton,
	IconButtonProps,
	styled,
	Typography,
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import type { ISelectRecord, ISelectUserField } from 'shared/api/select'
import { theme } from 'shared/theme'
import { browserLib, parsersLib } from 'shared/lib'
import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS, INPUT_BORDER_RADIUS } from 'shared/config'
import { useAppDispatch, useAppSelector } from 'shared/model'

import { DocGallery } from 'entities/documents'
import { userSelectors } from 'entities/user'

import { mapActions } from 'widgets/map/model'

import { TableData } from '../table-data'
import { GeomOnMap } from '../geom-on-map'
import { CopyRecordUrl } from '../copy-record-url'
import { useTranslate } from 'shared/i18n'

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props
	return <IconButton {...other} />
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}))

export const RecordData: React.FC<{
	record: ISelectRecord
	geomOnMap: boolean
	userFields: ISelectUserField[]
	defaultExpanded: boolean
	defaultViewOnMap?: boolean
	geom: string | null
	idSelect: number
	idLayer: number
	sys: string
	listenOnHover?: boolean
}> = ({
	record,
	userFields,
	geomOnMap,
	defaultExpanded,
	defaultViewOnMap,
	geom = null,
	idSelect,
	idLayer,
	sys,
	listenOnHover,
}) => {
	console.log('render record data')
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()

	const userInfo = useAppSelector(userSelectors.selectUserInfo)

	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)
	const [expanded, setExpanded] = useState(defaultExpanded)

	const galleryDocList = useMemo(() => {
		return record.doclist.filter((i) => !!i.hasPreview)
	}, [record])

	const listDocList = useMemo(() => {
		return record.doclist.filter((i) => !i.hasPreview)
	}, [record])

	useEffect(() => {
		if (!defaultViewOnMap || !geom) {
			return
		}

		console.log({ defaultViewOnMap })

		handleGeomOnMapClick()
	}, [defaultViewOnMap, geom])

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	const handleGeomOnMapClick = () => {
		dispatch(mapActions.setCurrentMapModeType(undefined))

		dispatch(
			mapActions.setInfoMapGeoms([
				{
					geom: geom || '',
					idSelect,
					idLayer,
					sys,
				},
			])
		)
	}

	const handleSharedClick = async () => {
		if (!userInfo?.nameUser) {
			alert('user name is undefined')
			return
		}

		const url = new URL(window.location.origin)
		url.pathname = `${userInfo.nameUser}/mo/${idSelect}/${idLayer}/${sys}`

		try {
			await browserLib.copyToClipboard(url.href)
		} catch (e) {
			console.log(e)
		}
	}

	const handleMouseEnter = () => {
		if (!listenOnHover) {
			return
		}

		dispatch(
			mapActions.setStatHoverMarkerSysData({
				idSelect,
				idLayer,
				sys,
				geom: geom || '',
			})
		)
	}

	const handleMouseLeave = () => {
		if (!listenOnHover) {
			return
		}
		dispatch(mapActions.setStatHoverMarkerSysData(null))
	}

	return (
		<Card
			variant="outlined"
			onClick={handleExpandClick}
			sx={{
				cursor: 'pointer',
				borderRadius: isMobile ? '25px' : INPUT_BORDER_RADIUS,
				backgroundColor: 'white',
				borderColor: theme.palette.grey['300'],
				'&:hover': {
					borderColor: theme.palette.grey['A400'],
				},
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<CardHeader
				title={
					<Typography
						variant="body1"
						fontWeight={500}
						dangerouslySetInnerHTML={{
							__html: parsersLib.clearString(record.metaName),
						}}
						sx={{
							wordBreak: 'break-word',
							whiteSpace: 'pre-wrap',
						}}
					/>
				}
			/>

			{!!galleryDocList.length && (
				<CardContent
					onClick={(evt) => evt.stopPropagation()}
					sx={{ cursor: 'default', '&:last-child': { pb: 2 } }}
				>
					<DocGallery documents={galleryDocList} viewMode="swiper" />
				</CardContent>
			)}

			<CardActions disableSpacing sx={{ pt: 0 }}>
				<CopyRecordUrl handleSharedClick={handleSharedClick} />

				{!!geom && (
					<GeomOnMap
						handleGeomOnMapClick={handleGeomOnMapClick}
						geomOnMap={geomOnMap}
					/>
				)}

				{geom !== null && !geom && (
					<Chip label={translate('Object is not on the map')} size="small" />
				)}

				<ExpandMore
					expand={expanded}
					size="small"
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>

			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent
					onClick={(evt) => evt.stopPropagation()}
					sx={{
						borderTop: 1,
						borderTopStyle: 'dashed',
						borderTopColor: 'divider',
						cursor: 'default',
						px: 0,
						'&:Last-child': { pb: 2 },
					}}
				>
					<TableData record={record} userFields={userFields} />
				</CardContent>

				{!!listDocList.length && (
					<CardContent
						onClick={(evt) => evt.stopPropagation()}
						sx={{ cursor: 'default', '&:Last-child': { pb: 2 } }}
					>
						<DocGallery documents={listDocList} viewMode="list" />
					</CardContent>
				)}
			</Collapse>
		</Card>
	)
}
