import ShareIcon from '@mui/icons-material/Share'
import { IconButton } from '@mui/material'
import { BootstrapTooltip } from 'shared/ui'
import { useTranslate } from 'shared/i18n'

export const CopyRecordUrl: React.FC<{ handleSharedClick: () => void }> = ({
	handleSharedClick,
}) => {
	const { translate } = useTranslate()
	const handleClick = (evt: React.MouseEvent) => {
		evt.stopPropagation()
		handleSharedClick()
	}

	return (
		<BootstrapTooltip title={translate('copy link')}>
			<IconButton
				size="small"
				aria-label="share"
				onClick={handleClick}
				sx={{ mr: 0.5 }}
			>
				<ShareIcon fontSize="small" />
			</IconButton>
		</BootstrapTooltip>
	)
}
