import { useCallback, useRef } from 'react'

import LocationOnIcon from '@mui/icons-material/LocationOn'

import { MapIconButton } from 'shared/ui'
import { useAppSelector } from 'shared/model'
import { useTranslate } from 'shared/i18n'
import { convertersLib } from 'shared/lib'

import { userSelectors } from 'entities/user'

import { mapLib, useMapContext } from 'widgets/map'

export const CurrentLocation = () => {
	const { map, dataInfoSource } = useMapContext()
	const { translate } = useTranslate()

	const boundBox = useAppSelector(userSelectors.selectUserMapBoundBox)

	const handleClick = useCallback(() => {
		dataInfoSource.clear()

		if (!map) {
			return
		}

		if (!navigator.geolocation) {
			alert('geolocation is not available in navigator')
		} else {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					try {
						const { longitude, latitude } = position.coords
						mapLib.moveToPoint({
							map,
							coords: [longitude, latitude],
							source: dataInfoSource,
							boundBox,
						})
					} catch (e) {
						const err = convertersLib.errorToString(e)
						alert(translate(err))
					}
				},
				(e) => {
					console.log(e)
				}
			)
		}
	}, [map, boundBox])

	return (
		<MapIconButton
			color="inherit"
			aria-label="directions"
			onClick={handleClick}
		>
			<LocationOnIcon />
		</MapIconButton>
	)
}
