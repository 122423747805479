import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'

import { useAppSelector } from 'shared/model'
import { userSelectors } from 'entities/user'
import { mapLib, mapSelectors, useMapContext } from 'widgets/map'

export const MapCopyright = () => {
	const productName = useAppSelector(userSelectors.getSettingById(11))
	const activeIdBaseLayer = useAppSelector(mapSelectors.selectActiveIdBaseLayer)
	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)
	const { map } = useMapContext()

	const [attr, setAttr] = useState<string | null>(null)

	useEffect(() => {
		if (!map || !activeIdBaseLayer || !mapOnLoaded) {
			setAttr(null)
			return
		}

		const baseLayers = mapLib.getMapBaseLayers({ map })

		baseLayers.forEach((lyr) => {
			if (String(lyr.get('idLayer')) === String(activeIdBaseLayer)) {
				const attr = lyr.get('attr')

				if (attr) {
					setAttr(attr)
				} else {
					setAttr(null)
				}
			}
		})
	}, [activeIdBaseLayer, map, mapOnLoaded])

	if (attr === null) {
		return null
	}

	return (
		<Box
			sx={{
				px: '10px',
				py: '1.5px',
				bgcolor: '#ffffff95',
				borderRadius: '8px',
			}}
		>
			<Typography
				variant="caption"
				sx={{ textShadow: '#fff 1px 0 10px', color: '#000!important' }}
				dangerouslySetInnerHTML={{
					__html: `<span style="white-space: nowrap">${productName} ${attr}</span>`,
				}}
			/>
		</Box>
	)
}
