import { useEffect, useState } from 'react'

import NavigationIcon from '@mui/icons-material/Navigation'

import { MapIconButton } from 'shared/ui'
import { useTranslate } from 'shared/i18n'

import { useMapContext } from 'widgets/map/context'

export const SetDefaultRotate = () => {
	const { map } = useMapContext()
	const { translate } = useTranslate()

	const [rotateAngle, setRotateAngle] = useState(0)

	useEffect(() => {
		if (!map) {
			return
		}

		map.getView().on('change:rotation', (evt) => {
			const angle = evt.target.getRotation()
			setRotateAngle(angle)
		})
	}, [map])

	const handleOnClick = () => {
		if (!map) {
			return
		}

		map.getView().animate({ rotation: 0 })
	}

	if (rotateAngle === 0) {
		return null
	}

	return (
		<MapIconButton onClick={handleOnClick}>
			<NavigationIcon
				color="primary"
				sx={{ transform: `rotate(${rotateAngle}rad)` }}
			/>
		</MapIconButton>
	)
}
