import { axiosInstance } from 'shared/api'
import { convertersLib, requestLib } from 'shared/lib'

export interface ISelectSysListData {
	idSelect: number
	syss: number[]
	count: number
}

export const fetchSelectSysList = (
	idSelect: number
): Promise<ISelectSysListData> =>
	axiosInstance
		.post(
			'SelectSearchResult',
			requestLib.encodePostParams({
				Select: idSelect,
				onlySys: 1,
				getAll: 1,
			})
		)
		.then((response) => response.data)
		.then((response) => response.SelectSearchResult)
		.then((response) => {
			requestLib.throwResponseError(response)

			return {
				idSelect,
				syss: convertersLib.anyToArray(response.sys),
				count: response.recCount || 0,
			}
		})
		.catch((e) => {
			throw e
		})
