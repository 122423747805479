import { axiosInstance } from 'shared/api'
import { convertersLib, groupLib, requestLib } from 'shared/lib'

import { IMapInfoRowData } from './types'
import { ICRFClassifierValue } from '../model'
import { groupByKey } from 'shared/lib/group'

export interface IRequestLayerWithCrf {
	id: string
	cf?: string
}

export interface IFetchMapInfoParams {
	layers: string[]
	lat?: number
	lng?: number
	zoom?: number
	crfValues?: ICRFClassifierValue[]
}

export interface IRequestMapInfoParams {
	layers?: string
	lat?: number
	lng?: number
	zoom?: number
}

export const fetchMapInfo = (
	params: IFetchMapInfoParams
): Promise<IMapInfoRowData[]> => {
	const requestParams: IRequestMapInfoParams = {
		lat: params.lat,
		lng: params.lng,
		zoom: params.zoom,
	}

	const layers: IRequestLayerWithCrf[] = []

	if (!!params.layers) {
		params.layers.forEach((idLayer) => {
			const layerCrfData = params.crfValues?.filter(
				(i) => String(i.idLayer) === String(idLayer)
			)

			const param: IRequestLayerWithCrf = {
				id: idLayer,
			}

			if (!!layerCrfData) {
				let cf = ''

				const groupedLayerCrfData = groupLib.groupByKey('crfLF', layerCrfData)

				groupedLayerCrfData.forEach((group) => {
					cf += `${group.keyValue}: ${group.items.map((i) => i.key).join(',')};`
				})

				if (!!cf.trim().length) {
					param.cf = cf
				}

				console.log({ layerCrfData, groupedLayerCrfData })
			}

			layers.push(param)
		})

		requestParams.layers = JSON.stringify(layers)
	}

	return axiosInstance
		.post('MapInfo', requestLib.encodePostParams(requestParams))
		.then((response) => response.data)
		.then((response) => response.MapInfo)
		.then((response) => {
			requestLib.throwResponseError(response)

			return convertersLib.anyToArray(response?.row)
		})
		.catch((e) => {
			throw e
		})
}
