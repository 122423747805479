import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { mapActions } from 'widgets/map/model'

export const ObserveContentType = () => {
	const dispatch = useAppDispatch()
	const leftSidebarContentType = useAppSelector(
		(state) => state.map.leftSidebarContentType
	)

	useEffect(() => {
		console.log({ leftSidebarContentType })
		if (leftSidebarContentType !== 'sys-data') {
			dispatch(mapActions.setStatIdSelect(null))
			dispatch(mapActions.setStatSelectSysGeom(null))
			dispatch(mapActions.setStatActiveMarkerSysData(null))
			dispatch(mapActions.setStatHoverMarkerSysData(null))
		}
	}, [leftSidebarContentType])

	return null
}
