import { useCallback, useEffect, useRef, useState } from 'react'
import {
	Autocomplete,
	AutocompleteRenderGroupParams,
	Box,
	Checkbox,
	Collapse,
	Stack,
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import type {
	IClassifierValue,
	IFetchClassifierValuesParams,
} from 'shared/api/classifier'

import { theme } from 'shared/theme'
import { classifierApi } from 'shared/api'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { useTranslate } from 'shared/i18n'
import { ICRFClassifierValue, mapActions, mapSelectors } from 'widgets/map'
import { BREAKPOINTS, INPUT_BORDER_RADIUS } from 'shared/config'
import { useIsBreakpoint } from 'shared/hooks'
import {
	MapTextField,
	AutocompleteGroupHeader,
	AutocompleteGroupItems,
} from 'shared/ui'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface IFetchClassifierValuesData {
	nameGroup: string
	idLayer: number
	crfCID: number
	crfLF: string
	fn: Promise<IClassifierValue[]>
}

export const CRFFilterSearch = () => {
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)
	const { translate } = useTranslate()
	const dispatch = useAppDispatch()

	const isOpenCRFFilterList = useAppSelector(
		mapSelectors.selectIsOpenCRFFilterList
	)
	const crfFilterAutocompleteIdLayerFocus = useAppSelector(
		mapSelectors.selectCrFilterAutocompleteIdLayerFocus
	)
	const crfValues = useAppSelector(mapSelectors.selectCRFClassifierValues)
	const crfUserLayers = useAppSelector(mapSelectors.selectCRFUserLayerList)

	const inputRef = useRef<HTMLInputElement | null>(null)

	const [inputValue, setInputValue] = useState('')
	const [options, setOptions] = useState<ICRFClassifierValue[]>([])
	const [idOpenGroups, setIdOpenGroups] = useState<string[]>([])
	const [openAllGroup, setOpenAllGroup] = useState(false)

	useEffect(() => {
		if (!isOpenCRFFilterList) {
			setOptions([])
			return
		}

		inputRef.current?.focus()
		;(async () => {
			await getOptions()
		})()
	}, [isOpenCRFFilterList])

	useEffect(() => {
		if (crfFilterAutocompleteIdLayerFocus === null) {
			return
		}

		dispatch(mapActions.setIsOpenCRFFilterList(true))
	}, [crfFilterAutocompleteIdLayerFocus])

	useEffect(() => {
		if (crfFilterAutocompleteIdLayerFocus === null || !options.length) {
			return
		}

		const filterOptions = options.filter(
			(i) => +i.idLayer === +crfFilterAutocompleteIdLayerFocus
		)

		if (!filterOptions.length) {
			return
		}

		setIdOpenGroups(filterOptions.map((i) => i.nameGroup))
	}, [crfFilterAutocompleteIdLayerFocus, options])

	useEffect(() => {
		setOpenAllGroup(!!inputValue)
	}, [inputValue])

	const getOptions = useCallback(async () => {
		const fetchData: IFetchClassifierValuesData[] = []

		for (let i = 0; i < crfUserLayers.length; i++) {
			const rules = crfUserLayers[i].classifierFilterRules

			rules.forEach((rule) => {
				const obj: IFetchClassifierValuesData = {
					nameGroup:
						`${crfUserLayers[i].LayerGroup.nameLayerGroup}: ${rule.cfrName}` ||
						'untitled',
					idLayer: crfUserLayers[i].id,
					crfCID: +rule.cfrCID,
					crfLF: rule.cfrLF,
					fn: fetchClassifierValues({
						classifier: rule.cfrCID,
						all: 1,
					}),
				}

				fetchData.push(obj)
			})
		}

		const promises: Promise<IClassifierValue[]>[] = fetchData.map((i) => i.fn)

		try {
			const response = await Promise.all(promises)

			const options: ICRFClassifierValue[] = response
				.map((values, idx) => {
					const data = fetchData[idx]

					return values.map((val) => ({
						crfLF: data.crfLF,
						crfCID: data.crfCID,
						idLayer: data.idLayer,
						nameGroup: data.nameGroup,
						...val,
					}))
				})
				.flat()

			setOptions(options)
		} catch (e) {
			console.log(e)
		}
	}, [crfUserLayers])

	const fetchClassifierValues = (params: IFetchClassifierValuesParams) => {
		return classifierApi.fetchClassifierValues(params)
	}

	const toggleOpenGroup = (params: AutocompleteRenderGroupParams) => {
		const { group } = params
		const idx = idOpenGroups.findIndex((i) => String(i) === String(group))

		if (idx === -1) {
			setIdOpenGroups((prev) => [...prev, group])
		} else {
			setIdOpenGroups((prev) => prev.filter((i) => String(i) !== String(group)))
		}
	}

	return (
		<Autocomplete
			id="map-data-filter-search"
			sx={{ flexGrow: 1 }}
			value={crfValues}
			inputValue={inputValue}
			options={options}
			open={isOpenCRFFilterList}
			isOptionEqualToValue={(option, value) => option.value === value.value}
			groupBy={(option) => option.nameGroup}
			getOptionLabel={(option) => option.value}
			renderOption={(props, option, { inputValue, selected }) => {
				const matches = match(option.value, inputValue, { insideWords: true })
				const parts = parse(option.value, matches)

				return (
					<li {...props} key={option.key}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{
									marginRight: 8,
									padding: '2.5px',
								}}
								checked={selected}
							/>

							<Box sx={{ flexGrow: 1 }}>
								{parts.map((part, index) => (
									<span
										key={index}
										style={{
											color: part.highlight
												? theme.palette.warning.dark
												: 'inherit',
										}}
									>
										{part.text}
									</span>
								))}
							</Box>
						</Stack>
					</li>
				)
			}}
			renderTags={() => null}
			renderGroup={(params) => (
				<li key={params.key}>
					<AutocompleteGroupHeader onClick={() => toggleOpenGroup(params)}>
						{params.group}
					</AutocompleteGroupHeader>
					<Collapse
						in={
							!!idOpenGroups.find((i) => String(i) === String(params.group)) ||
							openAllGroup
						}
						timeout={{ enter: 50, exit: 50 }}
						unmountOnExit
					>
						<AutocompleteGroupItems>{params.children}</AutocompleteGroupItems>
					</Collapse>
				</li>
			)}
			renderInput={(params) => (
				<MapTextField
					{...params}
					inputRef={inputRef}
					autoFocus
					bgColor={isMobile ? theme.palette.grey['200'] : 'white'}
					borderRadius={isMobile ? '25px' : INPUT_BORDER_RADIUS}
					placeholder={translate('Filter')}
					size={isMobile ? 'medium' : 'small'}
				/>
			)}
			multiple
			onOpen={() => {
				dispatch(mapActions.setIsOpenCRFFilterList(true))
			}}
			onClose={() => {
				dispatch(mapActions.setCrFilterAutocompleteIdLayerFocus(null))
				dispatch(mapActions.setIsOpenCRFFilterList(false))
			}}
			onChange={(_, value) => {
				dispatch(mapActions.setCRFClassifierValues(value))
			}}
			onInputChange={(event, value, reason) => {
				if (event && event.type === 'blur') {
					setInputValue('')
				} else if (reason !== 'reset') {
					setInputValue(value)
				}
			}}
			clearOnBlur
			clearOnEscape
			disableClearable
			disableCloseOnSelect
			disablePortal
			fullWidth
			noOptionsText={translate('no options')}
			loadingText={translate('loading')}
		/>
	)
}
