import { Box, Typography } from '@mui/material'

import { useTranslate } from 'shared/i18n'
import { LayerSwitcherItem } from '../layer-switcher-item'

import type { Layer } from 'ol/layer'
import type { Source } from 'ol/source'
import LayerRenderer from 'ol/renderer/Layer'

interface ISwitchOverlayLayersProps {
	layerList: Layer<Source, LayerRenderer<any>>[]
}

export const SwitchOverlayLayers: React.FC<ISwitchOverlayLayersProps> = ({
	layerList,
}) => {
	const { translate } = useTranslate()

	if (!layerList.length) {
		return null
	}

	return (
		<Box>
			{layerList.map((layer) => (
				<LayerSwitcherItem key={layer.get('idLayer')} layer={layer} />
			))}
		</Box>
	)
}
