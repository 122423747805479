import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const BootstrapTooltip = styled(
	({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	)
)(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		'&:first-letter': {
			textTransform: 'capitalize',
		},
	},
}))
