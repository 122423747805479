import { useAppSelector } from 'shared/model'
import { useEffect } from 'react'

export const withChangeDocumentTitle =
	(Component: new () => React.Component) => () => {
		const userProps = useAppSelector((state) => state.user.userProperties)
		const { nameOrganisation } = userProps?.Organisation || {}

		useEffect(() => {
			if (!nameOrganisation) {
				return
			}

			document.title = nameOrganisation
		}, [nameOrganisation])

		return <Component />
	}
