import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded'

import { IconButton, Collapse, Box } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { mapSelectors, mapActions } from 'widgets/map'

import { CRFFilterSearch } from '../../../crf-data-filter'
import { BUTTON_BORDER_RADIUS } from 'shared/config'
import { useTranslate } from 'shared/i18n'
import { BootstrapTooltip } from 'shared/ui'
import { useMemo } from 'react'

export const DesktopToggleCRFFilterSearch = () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()
	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)

	const crfUserLayers = useAppSelector(mapSelectors.selectCRFUserLayerList)
	const isOpen = useAppSelector(mapSelectors.selectIsOpenCRFFilterSearch)

	const iconColor = useMemo(() => {
		return isOpen ? 'info' : 'inherit'
	}, [isOpen])

	if (!crfUserLayers.length) {
		return null
	}

	return (
		<Box
			sx={{
				borderRadius: BUTTON_BORDER_RADIUS,
				bgcolor: 'white',
				display: 'flex',
				boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
				transition: 'box-shadow .1s ease-out',
				'&:hover': {
					boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',
				},
				justifyContent: 'space-between',
			}}
		>
			<Collapse
				orientation="horizontal"
				in={isOpen}
				timeout="auto"
				unmountOnExit
			>
				<Box sx={{ width: 300 }}>
					<CRFFilterSearch />
				</Box>
			</Collapse>

			<BootstrapTooltip
				title={translate(isOpen ? 'close filter' : 'open filter')}
			>
				<span>
					<IconButton
						color={iconColor}
						aria-label="directions"
						disabled={!mapOnLoaded}
						onClick={() =>
							dispatch(mapActions.setIsOpenCRFFilterSearch(!isOpen))
						}
					>
						{!isOpen ? <FilterAltRoundedIcon /> : <FilterAltOffRoundedIcon />}
					</IconButton>
				</span>
			</BootstrapTooltip>
		</Box>
	)
}
