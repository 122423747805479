import { IconButton } from '@mui/material'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import { BootstrapTooltip } from 'shared/ui'
import { useTranslate } from 'shared/i18n'

export const GeomOnMap: React.FC<{
	handleGeomOnMapClick: () => void
	geomOnMap: boolean
}> = ({ handleGeomOnMapClick, geomOnMap }) => {
	const { translate } = useTranslate()

	const handleClick = (evt: React.MouseEvent) => {
		evt.stopPropagation()
		handleGeomOnMapClick()
	}

	return (
		<BootstrapTooltip title={translate('showOnMap')}>
			<IconButton
				size="small"
				aria-label="share"
				onClick={handleClick}
				color={geomOnMap ? 'primary' : 'default'}
			>
				<MyLocationIcon fontSize="small" />
			</IconButton>
		</BootstrapTooltip>
	)
}
