import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useAppDispatch } from 'shared/model'
import type { RequestStatus } from 'shared/model'

import { convertersLib } from 'shared/lib'

import { userModel } from 'entities/user'
import { Box, Typography } from '@mui/material'
import { useTranslate } from 'shared/i18n'

export const withSearchQuery = (Component: new () => React.Component) => () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()
	const location = useLocation()

	const params = location.pathname.split('/').filter((i) => i !== '')

	const username = params[0]

	const [status, setStatus] = useState<RequestStatus>(undefined)
	const [errorMsg, setErrorMsg] = useState<string | null>(null)

	useEffect(() => {
		if (!username) {
			setStatus('success')
			return
		}

		const handleLogin = async (username: string) => {
			setStatus('loading')
			setErrorMsg(null)

			try {
				await dispatch(userModel.login({ username }))
					.unwrap()
					.catch((e) => {
						throw e
					})

				setStatus('success')
			} catch (e) {
				setStatus('error')
				setErrorMsg(convertersLib.errorToString(e))
			}
		}

		const handleLogout = async () => {
			setStatus('loading')
			setErrorMsg(null)

			try {
				await dispatch(userModel.logout())

				setStatus('success')
			} catch (e) {
				setStatus('error')
				setErrorMsg(convertersLib.errorToString(e))
			}
		}

		if (username) {
			handleLogin(username)
			return
		}
	}, [username])

	if (status === undefined) {
		return null
	}

	if (status === 'loading') {
		return null
	}

	if (status === 'error') {
		return (
			<Box
				sx={{
					height: '100dvh',
					width: '100dvw',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant="body1" textAlign="center" whiteSpace="nowrap">
					{translate('This is a portal for displaying open data')}.
				</Typography>

				<Typography
					variant="body1"
					sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
				>
					{translate(
						"For correct display follow the link from the organization's website"
					)}
					.
				</Typography>
			</Box>
		)
	}

	return <Component />
}
