import { Stack } from '@mui/material'

import {
	Measure,
	MobileToggleLayerSwitcher,
	MobileToggleCRFFilterSearch,
	MobileToggleLeftSidebar,
	FaqAnchor,
} from '../../../controls'
import { CurrentLocation } from 'widgets/map/ui/controls/current-location'
import { MapCopyright } from 'widgets/map/ui/controls/map-copyright'
import { SetDefaultRotate } from 'widgets/map/ui/controls/set-default-rotate'

export const MobileControlsLayout = () => {
	return (
		<>
			<Stack
				spacing={2}
				alignItems="start"
				sx={{
					position: 'fixed',
					left: '1rem',
					top: '1rem',
					width: 'auto',
				}}
			>
				<MobileToggleLeftSidebar />
			</Stack>

			<Stack
				spacing={2}
				alignItems="start"
				sx={{
					position: 'fixed',
					left: '1rem',
					bottom: '1rem',
					width: 'auto',
				}}
			>
				<MapCopyright />
			</Stack>

			<Stack
				spacing={2}
				alignItems="end"
				sx={{
					position: 'fixed',
					right: '1rem',
					top: '1rem',
					width: 'auto',
				}}
			>
				<MobileToggleCRFFilterSearch />
				<MobileToggleLayerSwitcher />
				<CurrentLocation />
				<SetDefaultRotate />
			</Stack>

			<Stack
				spacing={2}
				alignItems="end"
				sx={{
					position: 'fixed',
					right: '1rem',
					top: '50%',
					transform: 'translateY(-50%)',
					width: 'auto',
				}}
			></Stack>

			<Stack
				spacing={2}
				alignItems="end"
				sx={{
					position: 'fixed',
					right: '1rem',
					bottom: '1rem',
					width: 'auto',
				}}
			>
				<Measure />
				<FaqAnchor />
			</Stack>
		</>
	)
}
