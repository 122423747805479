import { ILayer } from 'shared/api/user'
import { Tile as TileLayer } from 'ol/layer'
import { XYZ } from 'ol/source'

export const createXYZTile = (layer: ILayer, isBase: boolean) => {
	const searchString = './MapProxy'
	const replaceString = window.location.origin + '/gisbis/MapProxy'

	let newWms = 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'

	if (layer.WMSName.startsWith('./MapProxy')) {
		newWms = layer.WMSName.replace(searchString, replaceString)
	}

	const xyzLayer = new TileLayer({
		source: new XYZ({
			url: newWms,
			//url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		}),
		properties: {
			title: layer.name,
			idLayer: layer.id,
			type: isBase ? 'base' : 'overlay',
			autoload: layer.autoload,
			group: layer.LayerGroup,
			classifierFilterRules: layer.classifierFilterRules,
			hideOnSatelliteView: layer.hideOnSatelliteView,
		},
		zIndex: isBase ? 0 : layer.zindex,
		visible: false,
		minZoom:
			!isNaN(+layer.minzoom) && layer.minzoom - 0.0001 > 0
				? layer.minzoom - 0.0001
				: layer.minzoom,
		maxZoom: layer.maxzoom,
	})

	xyzLayer.setZIndex(isBase ? 0 : layer.zindex)
	xyzLayer.setVisible(isBase ? false : !!layer.autoload)
	xyzLayer.setOpacity(layer.transparent)

	return xyzLayer
}
