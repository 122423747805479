import { withProviders } from './providers'

import { Routing } from 'pages'

import './index.scss'
import { AcceptCookies } from 'entities/accept-cookies/ui'

document.addEventListener('gesturestart', () => {})

const App = () => {
	return (
		<>
			<AcceptCookies />
			<Routing />
		</>
	)
}

export default withProviders(App)
