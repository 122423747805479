import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import LightGalleryReact from 'lightgallery/react'

import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgRotate from 'lightgallery/plugins/rotate'

import type { LightGallery } from 'lightgallery/lightgallery'
import type { GalleryItem } from 'lightgallery/lg-utils'

import 'lightgallery/css/lightgallery-bundle.min.css'

import type { IAttachment } from 'shared/api/documents'
import { DOC_DOWNLOAD_URL, DOC_PREVIEW_URL } from 'shared/config'
import { DocSwiper } from 'entities/documents/ui/doc-swiper'

import './index.scss'
import { DocList } from 'entities/documents/ui/doc-list'
import { docApi } from 'shared/api'

interface IDocGalleryProps {
	documents: IAttachment[]
	viewMode: 'swiper' | 'list'
}

export const DocGallery: React.FC<IDocGalleryProps> = ({
	documents,
	viewMode,
}) => {
	console.log('render doc gallery')
	const LGRef = useRef<LightGallery | null>(null)
	const [lgItems, setLgItems] = useState<GalleryItem[]>([])

	useEffect(() => {
		if (!LGRef.current) {
			return
		}

		LGRef.current.refresh()
	}, [lgItems])

	const onInit = useCallback((detail: any) => {
		if (detail) {
			LGRef.current = detail.instance
		}
	}, [])

	const getLgItems = (params: {
		documents: IAttachment[]
		idDoc: string
		pageCount?: number
		isPhoto: boolean
	}) => {
		const galleryItems: GalleryItem[] = []

		const { documents, isPhoto, pageCount = 0, idDoc } = params

		if (isPhoto) {
			const docs = documents.filter((i) => !!i.isPhoto)

			docs.forEach((doc) => {
				galleryItems.push({
					thumb: `${DOC_PREVIEW_URL}?docid=${doc.idDoc}&page=0&pictogram=1`,
					subHtml: doc.nameDoc,
					title: doc.nameDoc,
					src: `${DOC_PREVIEW_URL}?docid=${doc.idDoc}&page=0`,
					downloadUrl: `${DOC_DOWNLOAD_URL}?docid=${doc.idDoc}`,
				})
			})
		} else {
			const doc = documents.find((i) => String(i.idDoc) === String(idDoc))

			if (!doc) {
				return []
			}

			for (let i = 0; i < +pageCount; i++) {
				galleryItems.push({
					thumb: `${DOC_PREVIEW_URL}?docid=${doc.idDoc}&page=${i}&pictogram=1`,
					subHtml: doc.nameDoc,
					title: doc.nameDoc,
					src: `${DOC_PREVIEW_URL}?docid=${doc.idDoc}&page=${i}`,
					downloadUrl: `${DOC_DOWNLOAD_URL}?docid=${doc.idDoc}`,
				})
			}
		}

		return galleryItems
	}

	const openInGallery = useCallback(
		async (idDoc: string) => {
			const idx = documents.findIndex((i) => String(i.idDoc) === String(idDoc))

			if (idx === -1) {
				return
			}

			const doc = documents[idx]

			try {
				if (!!doc.isPhoto) {
					const lgItems = getLgItems({
						documents,
						idDoc: String(doc.idDoc),
						isPhoto: true,
					})

					setLgItems(lgItems)
				} else {
					try {
						const pageCount = await docApi.getPreviewPageCount({
							docid: String(doc.idDoc),
						})
						const lgItems = getLgItems({
							documents,
							idDoc: String(doc.idDoc),
							isPhoto: false,
							pageCount,
						})

						setLgItems(lgItems)
					} catch (e) {
						alert(JSON.stringify(e))
					}
				}

				setTimeout(() => {
					LGRef.current && LGRef.current.openGallery(0)
				}, 300)
			} catch (e) {
				setLgItems([])
			}
		},
		[documents]
	)

	return (
		<LightGalleryReact
			onInit={onInit}
			speed={500}
			plugins={[lgThumbnail, lgZoom, lgRotate]}
			selector=".lg-item"
			dynamic={true}
			dynamicEl={lgItems}
		>
			{viewMode === 'swiper' && (
				<DocSwiper documents={documents} handleDocClick={openInGallery} />
			)}

			{viewMode === 'list' && (
				<DocList documents={documents} handleDocClick={openInGallery} />
			)}
		</LightGalleryReact>
	)
}
