import CloseIcon from '@mui/icons-material/Close'
import { MapIconButton } from 'shared/ui/buttons'
import { BootstrapTooltip } from 'shared/ui'
import { useTranslate } from 'shared/i18n'

interface ICloseMeasureProps {
	cbcOnClick: (val: undefined) => void
}

export const CloseMeasure: React.FC<ICloseMeasureProps> = ({ cbcOnClick }) => {
	const { translate } = useTranslate()

	const handleClick = () => {
		cbcOnClick(undefined)
	}

	return (
		<BootstrapTooltip title={translate('Close')} placement="left">
			<MapIconButton onClick={handleClick}>
				<CloseIcon />
			</MapIconButton>
		</BootstrapTooltip>
	)
}
