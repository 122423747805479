import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { userSelectors } from 'entities/user'

import { mapModel, MapWidget } from 'widgets/map'

const MapPage = () => {
	const dispatch = useAppDispatch()

	const zoom = useAppSelector(userSelectors.getSettingById(15))
	const minZoom = useAppSelector(userSelectors.getSettingById(7636))
	const maxZoom = useAppSelector(userSelectors.getSettingById(7637))
	const lat = useAppSelector(userSelectors.getSettingById(13)) || 0
	const lng = useAppSelector(userSelectors.getSettingById(14)) || 0
	const boundBox = useAppSelector(userSelectors.selectUserMapBoundBox)

	useEffect(() => {
		dispatch(mapModel.getGlobalStat())
	}, [])

	return (
		<MapWidget
			zoom={zoom === undefined ? undefined : +zoom}
			maxZoom={maxZoom === undefined ? undefined : +maxZoom}
			minZoom={minZoom === undefined ? undefined : +minZoom}
			coords={[+lng, +lat]}
			boundBox={boundBox}
		/>
	)
}

export default MapPage
