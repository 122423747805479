import { Stack, Typography } from '@mui/material'
import { useAppSelector } from 'shared/model'
import { userSelectors } from 'entities/user'
import { parsersLib } from 'shared/lib'

export const Bio = () => {
	const userProps = useAppSelector(userSelectors.selectUserProps)

	if (userProps === null) {
		return null
	}

	const { descriptionUser, remarkUser } = userProps

	return (
		<Stack spacing={3} alignItems="center" justifyContent="center">
			{!!descriptionUser && (
				<Typography
					variant="h5"
					textAlign="center"
					sx={{ textWrap: 'balance' }}
					dangerouslySetInnerHTML={{
						__html: parsersLib.clearString(descriptionUser),
					}}
				/>
			)}
			{!!remarkUser && (
				<Typography
					variant="body1"
					textAlign="center"
					sx={{ textWrap: 'balance' }}
					dangerouslySetInnerHTML={{
						__html: parsersLib.clearString(remarkUser),
					}}
				/>
			)}
		</Stack>
	)
}
