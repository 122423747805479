import { useCallback, useEffect } from 'react'
import { useAppSelector } from 'shared/model'
import { useMapContext } from 'widgets/map/context'
import { markerSelectStyle, markerStyle } from 'widgets/map/config'

import BaseEvent from 'ol/events/Event'
import { MapBrowserEvent } from 'ol'

let selected: any = null

export const OnPointerMove = () => {
	const { map } = useMapContext()
	const mapModeType = useAppSelector((state) => state.map.currenMapModeType)

	useEffect(() => {
		if (!map || mapModeType === 'measure' || mapModeType === 'edit') {
			return
		}

		map.addEventListener('pointermove', handlePointerMove)

		return () => {
			map.removeEventListener('pointermove', handlePointerMove)
		}
	}, [map, mapModeType])

	const handlePointerMove = useCallback(
		(evt: Event | BaseEvent) => {
			if (selected !== null) {
				selected.setStyle(markerStyle)
				selected = null
			}

			if (!map) {
				return
			}

			const mapEvt = evt as MapBrowserEvent<any>

			map.forEachFeatureAtPixel(mapEvt.pixel, function (feat, layer) {
				const props = feat?.getProperties()[0]
				const featMarkerType = props?.['markerType']

				if (featMarkerType && featMarkerType === 'mapInfoMarker') {
					selected = feat
					selected.setStyle(markerSelectStyle)

					return true
				}
			})
		},
		[map]
	)

	return null
}
