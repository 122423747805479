import { PropsWithChildren, useCallback } from 'react'
import { Box, Button, Divider, IconButton, Stack, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { FullScreenPageLayout } from 'shared/ui'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { DEFAULT_SIDEBAR_WIDTH } from 'shared/config'

import { mapActions, mapSelectors } from 'widgets/map'

import { LayerSwitcherWrapper } from '../../../layer-switcher'
import { SymbolsWrapper } from '../../../symbols'
import { Bio } from '../../../bio'
import { MapData } from '../../../map-data'
import { MapSearch } from '../../../map-search'

import {
	DesktopToggleCRFFilterSearch,
	DesktopToggleLayerSwitcher,
	DesktopToggleLeftSidebar,
	DesktopToggleSymbolList,
	FaqAnchor,
	MapCopyright,
	Measure,
	MousePositionControl,
	ZoomGroup,
} from '../../../controls'

import { CRFFilterResult } from '../../../crf-data-filter'
import { GlobalStat } from '../../../global-stat'
import { SelectSysData } from 'widgets/map/ui/sys-data'
import { SetDefaultRotate } from 'widgets/map/ui/controls/set-default-rotate'

const Sidebar = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'open' && prop !== 'sidebarwidth' && prop !== 'anchor',
})<{
	open: boolean
	sidebarwidth: string
	anchor: 'left' | 'right'
}>(({ theme, open, anchor, sidebarwidth }) => ({
	backgroundColor: '#fff',
	position: 'absolute',
	width: sidebarwidth,
	minWidth: sidebarwidth,
	maxWidth: sidebarwidth,
	height: '100%',
	overflowY: 'hidden',
	left: anchor === 'left' ? `-${sidebarwidth}` : 'auto',
	right: anchor === 'right' ? `-${sidebarwidth}` : 'auto',
	zIndex: 1,
	boxShadow:
		anchor === 'left'
			? '2px 0 2px 0 rgb(0 0 0 / 10%)'
			: '0 2px 0 2px rgb(0 0 0 / 10%)',
	transition: theme.transitions.create(['left', 'right'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create(['left', 'right'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		left: anchor === 'left' ? 0 : 'auto',
		right: anchor === 'right' ? 0 : 'auto',
	}),
}))

const ControlWrapper = styled('div', {
	shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sidebarwidth',
})<{
	open: boolean
	vertalign: 'top' | 'middle' | 'bottom'
	horalign: 'left' | 'middle' | 'right'
	sidebarwidth: string
	anchor: 'left' | 'right'
	right?: string
	left?: string
}>(
	({
		theme,
		open,
		sidebarwidth,
		vertalign,
		horalign,
		anchor,
		right = '1rem',
		left = '1rem',
	}) => ({
		position: 'absolute',
		top: vertalign === 'top' ? '1rem' : vertalign === 'middle' ? '50%' : 'auto',
		bottom: vertalign === 'bottom' ? '1rem' : 'auto',
		left: horalign === 'left' ? left : horalign === 'middle' ? '50%' : 'auto',
		right: horalign === 'right' ? right : 'auto',
		transform:
			vertalign === 'middle' && horalign === 'middle'
				? 'translateXY(-50%)'
				: vertalign === 'middle'
				? 'translateY(-50%)'
				: horalign === 'middle'
				? 'translateX(-50%)'
				: 'none',
		zIndex: 1,
		transition: theme.transitions.create(['right', 'left'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			transition: theme.transitions.create(['right', 'left'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			right: anchor === 'right' ? `calc(${right} + ${sidebarwidth})` : 'auto',
			left: anchor === 'left' ? `calc(${left} + ${sidebarwidth})` : 'auto',
		}),
	})
)

export const DesktopPageLayout: React.FC<PropsWithChildren> = ({
	children,
}) => {
	console.log('render desktop layout')
	const dispatch = useAppDispatch()

	const isOpenCRFFilter = useAppSelector(
		(state) => state.map.isOpenCrfFilterSearch
	)

	const isOpenRightSidebar = useAppSelector(
		(state) => state.map.isOpenRightSidebar
	)
	const isOpenLeftSidebar = useAppSelector(
		(state) => state.map.isOpenLeftSidebar
	)

	const leftSidebarContentType = useAppSelector(
		(state) => state.map.leftSidebarContentType
	)
	const rightSidebarContentType = useAppSelector(
		(state) => state.map.rightSidebarContentType
	)
	const mapOnLoadEnd = useAppSelector((state) => state.map.mapOnLoadEnd)

	const renderLeftSidebarContent = useCallback(() => {
		return (
			<Box
				sx={{
					height: '100%',
					overflowY: 'hidden',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box sx={{ height: '78px', minHeight: '78px', bgcolor: 'white' }}></Box>

				{leftSidebarContentType === 'map-data' && (
					<Box
						sx={{ pb: 3, pt: 0.5, px: 3, flexGrow: 1, overflowY: 'auto' }}
						className="scrollbar-hidden"
					>
						<MapData />
					</Box>
				)}

				{leftSidebarContentType === 'sys-data' && (
					<Box
						sx={{ px: 3, pb: 3, flexGrow: 1, overflowY: 'hidden' }}
						className="scrollbar-hidden"
					>
						<SelectSysData />
					</Box>
				)}

				{leftSidebarContentType === 'home-screen' && (
					<Box
						sx={{
							flexGrow: 1,
							overflowY: 'auto',
						}}
						className="scrollbar-hidden"
					>
						<Box
							sx={{
								p: 3,
							}}
						>
							<Box sx={{ mb: 10 }}>
								<Bio />
							</Box>

							<Box>
								<GlobalStat />
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		)
	}, [leftSidebarContentType])

	const renderRightSidebarContent = useCallback(() => {
		if (!mapOnLoadEnd) {
			return null
		}

		return (
			<Box
				sx={{
					height: '100%',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ px: 3, pb: 3, pt: '1rem' }}>
					{rightSidebarContentType === 'layer-switcher' && (
						<LayerSwitcherWrapper />
					)}

					{rightSidebarContentType === 'symbol-list' && <SymbolsWrapper />}
				</Box>
			</Box>
		)
	}, [rightSidebarContentType, mapOnLoadEnd])

	const handleCloseRightSidebar = () => {
		dispatch(mapActions.setIsOpenRightSidebar(false))
	}

	return (
		<>
			<Box
				sx={{
					position: 'fixed',
					top: '0',
					left: '0',
					zIndex: 2,
					width: DEFAULT_SIDEBAR_WIDTH,
				}}
			>
				<Box sx={{ px: 3, py: '1rem' }}>
					<MapSearch />
				</Box>
			</Box>

			<FullScreenPageLayout>
				<>
					<ControlWrapper
						open={isOpenLeftSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						vertalign="middle"
						horalign="left"
						anchor="left"
						left="0rem"
					>
						<DesktopToggleLeftSidebar />
					</ControlWrapper>

					<ControlWrapper
						open={isOpenRightSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						vertalign="top"
						horalign="right"
						anchor="right"
					>
						<Stack spacing={3} alignItems="end">
							<DesktopToggleLayerSwitcher />
							<Measure />
						</Stack>
					</ControlWrapper>

					<ControlWrapper
						open={isOpenRightSidebar}
						vertalign="top"
						horalign="right"
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						anchor="right"
						right="7rem"
					>
						<Stack spacing={0.5}>
							<DesktopToggleCRFFilterSearch />

							{isOpenCRFFilter && (
								<Box
									sx={{
										maxWidth: '312.5px',
										bgcolor: '#ffffff70',
										backdropFilter: 'blur(5px)',
										p: 1.5,
										borderRadius: '14px',
										boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
									}}
								>
									<CRFFilterResult />
								</Box>
							)}
						</Stack>
					</ControlWrapper>

					<ControlWrapper
						open={isOpenRightSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						vertalign="middle"
						horalign="right"
						anchor="right"
					>
						<Stack spacing={3} alignItems="end">
							<ZoomGroup />
						</Stack>
					</ControlWrapper>

					<ControlWrapper
						open={isOpenRightSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						vertalign="bottom"
						horalign="right"
						anchor="right"
					>
						<Stack spacing={3} alignItems="end">
							<DesktopToggleSymbolList />
							<FaqAnchor />
							<MapCopyright />
						</Stack>
					</ControlWrapper>

					<Sidebar
						open={isOpenLeftSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						anchor="left"
					>
						{renderLeftSidebarContent()}
					</Sidebar>

					<Sidebar
						open={isOpenRightSidebar}
						sidebarwidth={DEFAULT_SIDEBAR_WIDTH}
						anchor="right"
					>
						{renderRightSidebarContent()}
						<IconButton
							sx={{
								position: 'absolute',
								top: '1rem',
								right: '1.5rem',
								zIndex: 2,
							}}
							onClick={handleCloseRightSidebar}
						>
							<CloseIcon />
						</IconButton>
					</Sidebar>

					<Box
						sx={{
							height: '100%',
							flexGrow: '1',
							overflow: 'hidden',
							display: 'flex',
						}}
					>
						{children}
					</Box>
				</>
			</FullScreenPageLayout>
		</>
	)
}
