import { useAppSelector } from 'shared/model'
import { useMapContext } from 'widgets/map/context'
import { useEffect } from 'react'
import { ISysGeom } from 'widgets/map/api/fetch-select-sys-geometry'
import { mapLib } from 'widgets/map'
import { markerStyle } from 'widgets/map/config'
import { Point } from 'ol/geom'
import { Feature } from 'ol'
import VectorLayer from 'ol/layer/Vector'
import { Coordinate } from 'ol/coordinate'
import { selectSelectors } from 'entities/select'

export const ObserveSysListForMap = () => {
	const statSelectSysGeom = useAppSelector(
		(state) => state.map.statSelectSysGeom
	)

	const statIdSelect = useAppSelector((state) => state.map.statIdSelect)

	const idLayer = useAppSelector(
		selectSelectors.selectIdLayerByIdSelect(statIdSelect)
	)

	const { map, dataGlobalInfoSource } = useMapContext()

	useEffect(() => {
		if (!map) {
			return
		}

		if (!statSelectSysGeom) {
			clearMarkers()
			return
		}

		renderMarkers()
	}, [map, statSelectSysGeom])

	const clearMarkers = () => {
		dataGlobalInfoSource.clear()
	}

	const renderMarkers = () => {
		clearMarkers()

		if (!map) {
			return
		}

		const markerFeatures: Feature<Point>[] = []

		statSelectSysGeom?.forEach((geomData) => {
			const marker = createMarker(geomData)
			marker && markerFeatures.push(marker)
		})

		dataGlobalInfoSource.addFeatures(markerFeatures)

		const markerLayer = new VectorLayer({
			visible: true,
			source: dataGlobalInfoSource,
			zIndex: 9998,
		})

		markerLayer.setZIndex(9998)
		markerLayer.setVisible(true)

		map.addLayer(markerLayer)
	}

	const createMarker = (geomData: ISysGeom) => {
		const { geom, sys } = geomData

		const features = mapLib.getFeaturesFromGeom(geom)

		if (!features || !features?.length) {
			return
		}

		const feature = features[0]
		const type = feature.geometry.type
		let coords: Coordinate = [0, 0]

		if (type === 'Point') {
			coords = feature.geometry.coordinates
		} else if (type === 'Polygon') {
			const extCoords = feature.geometry.coordinates[0]

			const coordsX = []
			const coordsY = []

			for (let i = 0; i < extCoords.length; i++) {
				coordsX.push(extCoords[i][0])
				coordsY.push(extCoords[i][1])
			}

			const maxAllXCoords = Math.max.apply(Math, coordsX)
			const minAllXCoords = Math.min.apply(Math, coordsX)
			const maxAllYCoords = Math.max.apply(Math, coordsY)
			const minAllYCoords = Math.min.apply(Math, coordsY)

			const centerAllXCoords = ((maxAllXCoords + minAllXCoords) / 2).toFixed(8)
			const centerAllYCoords = ((maxAllYCoords + minAllYCoords) / 2).toFixed(8)

			coords = [+centerAllXCoords, +centerAllYCoords]
		}

		const markerGeometry = new Point(coords)
		const markerFeature = new Feature({
			geometry: markerGeometry,
		})

		markerFeature.setStyle(markerStyle)
		markerFeature.setProperties([
			{
				sys,
				geom,
				idSelect: statIdSelect,
				idLayer: idLayer,
				markerType: 'mapInfoMarker',
			},
		])

		return markerFeature
	}

	return null
}
