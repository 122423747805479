import { Stack, Typography } from '@mui/material'

import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS } from 'shared/config'
import { useAppSelector } from 'shared/model'
import { SelectData } from './select-data'
import { useTranslate } from 'shared/i18n'
import { IMapInfoRowData } from 'widgets/map/api'

const returnKey = (dataRow: IMapInfoRowData) =>
	`key-${dataRow.sys}-${dataRow.id}-${dataRow.layerInfo.layerID}-${dataRow.selectInfo.selectID}`

export const MapData = () => {
	const { translate } = useTranslate()
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)

	const mapInfoData = useAppSelector((state) => state.map.mapInfoData) || []

	if (!mapInfoData.length) {
		return (
			<Typography variant="body1" textAlign="center">
				{translate('Data not found')}
			</Typography>
		)
	}

	return (
		<Stack spacing={1.5}>
			{mapInfoData.map((dataRow, index) => (
				<SelectData
					key={returnKey(dataRow)}
					dataRow={dataRow}
					idx={index}
					isExpanded={mapInfoData.length === 1 && !isMobile}
				/>
			))}
		</Stack>
	)
}

export { ClearData } from './clear-data'
