export const setValue = (key: string, value: string) => {
	try {
		window.localStorage.setItem(key, value)
	} catch (e) {
		throw e
	}
}

export const getValue = (key: string) => {
	try {
		return window.localStorage.getItem(key)
	} catch (e) {
		return null
	}
}
