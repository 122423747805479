import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'

import { MapIconButton } from 'shared/ui'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { mapActions, mapSelectors } from 'widgets/map/model'

export const MobileToggleCRFFilterSearch = () => {
	const dispatch = useAppDispatch()
	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)

	const handleClick = () => {
		dispatch(mapActions.setDrawerContentType('crf-filter'))
		dispatch(mapActions.setIsOpenDrawer(true))
	}

	return (
		<MapIconButton onClick={handleClick} disabled={!mapOnLoaded}>
			<FilterAltRoundedIcon />
		</MapIconButton>
	)
}
