import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { BackdropSpinner } from 'shared/ui'

import {
	useMapContext,
	mapModel,
	mapActions,
	mapLib,
	mapSelectors,
	ICRFClassifierValue,
} from 'widgets/map'

import type { MapBrowserEvent } from 'ol'
import BaseEvent from 'ol/events/Event'
import { transform } from 'ol/proj'
import { useStore } from 'react-redux'

export const OnSingleClick = () => {
	const dispatch = useAppDispatch()
	const { map } = useMapContext()
	const mapModeType = useAppSelector((state) => state.map.currenMapModeType)
	const currentZoom = useAppSelector((state) => state.map.currentZoom)
	const status = useAppSelector((state) => state.map.status)

	const { getState } = useStore()

	useEffect(() => {
		if (
			!map ||
			mapModeType === 'measure' ||
			mapModeType === 'edit' ||
			!currentZoom
		) {
			return
		}

		map.addEventListener('singleclick', handleSingleClick)

		return () => {
			map.removeEventListener('singleclick', handleSingleClick)
		}
	}, [map, mapModeType, currentZoom])

	const handleSingleClick = useCallback(
		(evt: Event | BaseEvent) => {
			if (!map || currentZoom === undefined) {
				return
			}

			const mapEvt = evt as MapBrowserEvent<any>

			const atPixelData = map.forEachFeatureAtPixel(
				mapEvt.pixel,
				function (feat, layer) {
					return { feat, layer }
				}
			)

			if (atPixelData) {
				const { layer, feat } = atPixelData

				const props = feat?.getProperties()[0]
				const featMarkerType = props?.['markerType']
				const featSys = props?.['sys']
				const featGeom = props?.['geom']
				const featIdLayer = props?.['idLayer']
				const featIdSelect = props?.['idSelect']

				if (featMarkerType && featMarkerType === 'mapInfoMarker') {
					dispatch(
						mapActions.setStatActiveMarkerSysData({
							sys: featSys,
							geom: featGeom,
							idLayer: featIdLayer,
							idSelect: featIdSelect,
						})
					)

					return
				}
			}

			getMapInfo(mapEvt, currentZoom)
		},
		[map, currentZoom]
	)

	const getMapInfo = (mapEvt: MapBrowserEvent<any>, currentZoom: number) => {
		if (!map) {
			return
		}

		const crfValues = (getState() as RootState).map.crfClassifierValues

		const coordinate = mapEvt.coordinate
		const lnglat = transform(coordinate, 'EPSG:3857', 'EPSG:4326')
		const lng = lnglat[0]
		const lat = lnglat[1]

		const zoom = Math.round(currentZoom)

		const activeLayers = mapLib.getMapActiveOverlayLayers({
			map,
			zoom: currentZoom,
		})

		if (!activeLayers.length) {
			return
		}

		const layers = activeLayers.map((i) => i.get('idLayer')).filter((i) => !!i)

		dispatch(
			mapModel.getMapInfo({
				lat,
				lng,
				zoom,
				layers,
				crfValues,
			})
		)
			.unwrap()
			.finally(() => {
				dispatch(mapActions.setLeftSidebarContentType('map-data'))
				dispatch(mapActions.setIsOpenLeftSidebar(true))

				dispatch(mapActions.setDrawerContentType('map-data'))
				dispatch(mapActions.setIsOpenDrawer(true))
			})
	}

	return status === 'loading' ? <BackdropSpinner /> : null
}
