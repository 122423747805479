import { IAttachment } from 'shared/api/documents'
import { Box, Typography } from '@mui/material'

interface IDocListItemProps {
	document: IAttachment
	handleOnClick: (idDoc: string) => void
}
export const DocListItem: React.FC<IDocListItemProps> = ({
	document,
	handleOnClick,
}) => {
	return (
		<Box onClick={() => handleOnClick(String(document.idDoc))}>
			<Typography>{document.nameDoc}</Typography>
		</Box>
	)
}
