import { useEffect, useMemo, useState } from 'react'
import { Skeleton, Stack } from '@mui/material'

import { RequestStatus, useAppSelector } from 'shared/model'
import { convertersLib } from 'shared/lib'
import { selectApi } from 'shared/api'
import type { ISelectRecord, ISelectUserField } from 'shared/api/select'

import { mapSelectors } from 'widgets/map'
import type { IMapInfoRowData } from 'widgets/map/api'

import { RecordData } from '../record-data'

export const SelectData: React.FC<{
	dataRow: IMapInfoRowData
	idx: number
	isExpanded: boolean
}> = ({ dataRow, idx, isExpanded }) => {
	const geomsOnMap = useAppSelector(mapSelectors.selectInfoMapGeoms)

	const { selectInfo, id, geom, layerInfo, sys } = dataRow

	const [userFields, setUserFields] = useState<ISelectUserField[]>([])
	const [recordList, setRecordList] = useState<ISelectRecord[]>([])

	const [errorMsg, setErrorMsg] = useState<string | null>(null)
	const [status, setStatus] = useState<RequestStatus>(undefined)

	useEffect(() => {
		;(async () => {
			await getSelectData()
		})()
	}, [dataRow])

	const geomOnMap = useMemo(() => {
		return (
			!!geomsOnMap &&
			!!geomsOnMap.find(
				(i) =>
					String(i.sys) === String(dataRow.sys) &&
					i.idSelect === dataRow.selectInfo.selectID &&
					i.idLayer === dataRow.layerInfo.layerID
			)
		)
	}, [geomsOnMap, dataRow])

	const getSelectData = async () => {
		setStatus('loading')
		setErrorMsg(null)

		try {
			const [recordData, userFieldsData] = await Promise.all([
				selectApi.fetchSelectSearchResult({
					Select: String(selectInfo.selectID),
					id: String(id),
				}),
				selectApi.fetchSelectUserFields({ idSelect: '' + selectInfo.selectID }),
			])

			setRecordList(recordData.searchResult)
			setUserFields(userFieldsData.userFields)

			setStatus('success')
		} catch (e) {
			setStatus('error')
			setErrorMsg(convertersLib.errorToString(e))
		}
	}

	if (status === undefined) {
		return null
	}

	if (status === 'loading') {
		return <Skeleton variant="rectangular" height={60} />
	}

	if (status === 'error') {
		return <div>{errorMsg}</div>
	}

	return (
		<Stack spacing={1.5}>
			{recordList.map((record, index) => (
				<RecordData
					key={record.key}
					record={record}
					userFields={userFields}
					geomOnMap={geomOnMap}
					geom={geom}
					idLayer={+layerInfo.layerID}
					idSelect={+selectInfo.selectID}
					sys={String(sys)}
					defaultExpanded={isExpanded && recordList.length === 1}
					defaultViewOnMap={index === 0 && idx === 0}
				/>
			))}
		</Stack>
	)
}
