import LayersIcon from '@mui/icons-material/Layers'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { MapIconButton } from 'shared/ui'

import { mapActions, mapSelectors } from 'widgets/map'

export const MobileToggleLayerSwitcher = () => {
	const dispatch = useAppDispatch()
	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)

	const handleOnClick = () => {
		dispatch(mapActions.setDrawerContentType('layer-switcher'))
		dispatch(mapActions.setIsOpenDrawer(true))
	}

	return (
		<MapIconButton onClick={handleOnClick} disabled={!mapOnLoaded}>
			<LayersIcon />
		</MapIconButton>
	)
}
