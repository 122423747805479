import { PropsWithChildren } from 'react'
import { Global } from '@emotion/react'
import {
	Box,
	CssBaseline,
	styled,
	SwipeableDrawer,
	SwipeableDrawerProps,
} from '@mui/material'

import { theme } from 'shared/theme'

const drawerBleeding = 26

const Root = styled('div')(({ theme }) => ({
	height: '100dvh',
	backgroundColor: theme.palette.background.default,
}))

const Puller = styled(Box)(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: 'white',
	borderRadius: 3,
	position: 'absolute',
	boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
	top: 8,
	left: 'calc(50% - 15px)',
}))

interface IWithDrawerPageLayoutProps extends PropsWithChildren {
	drawerContent: JSX.Element | null
	drawerProps: SwipeableDrawerProps
	drawerHeight: string
}

export const WithDrawerPageLayout: React.FC<IWithDrawerPageLayoutProps> = ({
	children,
	drawerContent,
	drawerProps,
	drawerHeight,
}) => {
	const container = window !== undefined ? window.document.body : undefined

	return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height:
							drawerHeight === 'auto'
								? drawerHeight
								: `calc(${drawerHeight} - ${drawerBleeding}px)`,
						maxHeight: `calc(100dvh - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			/>

			{children}

			<SwipeableDrawer
				container={container}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
				{...drawerProps}
			>
				<Box
					sx={{
						position: 'absolute',
						top: -drawerBleeding,
						bottom: 0,
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						right: 0,
						left: 0,
					}}
				>
					<Puller />
				</Box>
				<Box
					sx={{
						height: '100%',
						overflow: 'auto',
						backgroundColor: theme.palette.background.default,
					}}
				>
					{drawerContent}
				</Box>
			</SwipeableDrawer>
		</Root>
	)
}
