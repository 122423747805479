import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'shared/model'

import { mapActions, mapApi, mapSelectors } from 'widgets/map'
import { IMapInfoRowData } from 'widgets/map/api'

export const ObserveUriParams = () => {
	const dispatch = useAppDispatch()

	const idActiveLayerList = useAppSelector(mapSelectors.selectActiveIdLayerList)

	const { paramsType, param1, param2, param3 } = useParams()

	useEffect(() => {
		if (!paramsType) {
			return
		}

		if (paramsType === 'mo') {
			if (!param1 || !param2 || !param3) {
				return
			}

			;(async () => {
				await showGeom(param1, param2, param3)
			})()
		}
	}, [param1, param2, param3, paramsType])

	const showGeom = async (idSelect: string, idLayer: string, sys: string) => {
		try {
			const geomDataList = await mapApi.fetchGeom({
				idLayer: +idLayer,
				syss: String(sys),
			})

			const { geom = '' } = geomDataList[0]

			const infoData: IMapInfoRowData = {
				sys: String(sys),
				id: String(sys),
				layerInfo: {
					layerID: +idLayer,
				},
				selectInfo: {
					selectID: +idSelect,
				},
				geom,
			}

			dispatch(mapActions.setMapinfoData([infoData]))
			dispatch(mapActions.setLeftSidebarContentType('map-data'))
			dispatch(mapActions.setIsOpenLeftSidebar(true))

			setActiveLayer(+idLayer)
		} catch (e) {
			console.log(e)
		} finally {
			window.history.pushState(null, '', window.location.origin)
		}
	}

	const setActiveLayer = useCallback(
		(idLayer: number) => {
			if (!idActiveLayerList.map((i) => +i).includes(+idLayer)) {
				dispatch(
					mapActions.setActiveIdLayerList([...idActiveLayerList, +idLayer])
				)
			}
		},
		[idActiveLayerList]
	)

	return null
}
