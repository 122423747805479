import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'

export const markerStyle = new Style({
	image: new CircleStyle({
		radius: 6,
		fill: new Fill({
			color: '#3399CC',
		}),
		stroke: new Stroke({
			color: '#fff',
			width: 2,
		}),
	}),
})

export const markerSelectStyle = new Style({
	image: new CircleStyle({
		radius: 8,
		fill: new Fill({
			color: '#FC8507',
		}),
		stroke: new Stroke({
			color: '#fff',
			width: 2,
		}),
	}),
})
