import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchGlobalStat, fetchMapInfo, IFetchMapInfoParams } from '../api'
import { fetchSelectSysList } from 'widgets/map/api/fetch-select-sys-list'
import { fetchSelectSysGeometry } from 'widgets/map/api/fetch-select-sys-geometry'

export const getMapInfo = createAsyncThunk(
	'mapInfo/getMapInfo',
	(params: IFetchMapInfoParams) => fetchMapInfo(params)
)

export const getGlobalStat = createAsyncThunk('mapInfo/getGlobalStat', () =>
	fetchGlobalStat()
)

export const getSelectSysGeom = createAsyncThunk(
	'mapInfo/getSelectSysGeom',
	async (params: { idSelect: number; idLayer: number }) => {
		const { syss } = await fetchSelectSysList(params.idSelect)

		return await fetchSelectSysGeometry({
			idLayer: params.idLayer,
			syss: syss.join(','),
		})
	}
)
