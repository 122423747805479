import LogoDark from 'shared/assets/images/logo2.svg'
import LogoLight from 'shared/assets/images/gb.svg'

interface ILogo {
	size: string
	mode: 'light' | 'dark'
}

export const Logo: React.FC<ILogo> = ({ mode, size }) => {
	return (
		<img
			src={mode === 'dark' ? LogoLight : LogoDark}
			width={size}
			height={size}
			style={{ minWidth: size, minHeight: size }}
		/>
	)
}
