import { useAppSelector } from 'shared/model'
import { mapSelectors } from 'widgets/map/model'
import { useEffect } from 'react'
import { useMapContext } from 'widgets/map/context'
import { mapLib } from 'widgets/map/index'
import { userSelectors } from 'entities/user'

export const ObserveChangeBaseLayer = () => {
	const { map } = useMapContext()
	const activeIdBaseLayer = useAppSelector(mapSelectors.selectActiveIdBaseLayer)
	const idLayerScheme = useAppSelector(userSelectors.getSettingById(21))

	useEffect(() => {
		if (!map) {
			return
		}

		const overlayLayerList = mapLib.getMapOverlayLyers({ map })

		if (String(activeIdBaseLayer) === String(idLayerScheme)) {
			overlayLayerList.forEach((lyr) => {
				lyr.setOpacity(1)
			})

			return
		}

		overlayLayerList.forEach((lyr) => {
			lyr.setOpacity(Number(!lyr.get('hideOnSatelliteView')))
		})
	}, [map, activeIdBaseLayer, idLayerScheme])

	return null
}
