import type { IStatRecord } from '../model'
import { axiosInstance } from 'shared/api'
import { convertersLib, requestLib } from 'shared/lib'

export const fetchGlobalStat = (): Promise<IStatRecord[]> =>
	axiosInstance
		.get('SelectGlobalStatistics')
		.then((response) => response.data?.SelectGlobalStatistics)
		.then((response) => {
			requestLib.throwResponseError(response)

			return convertersLib.anyToArray(response?.row)
		})
		.catch((e) => {
			throw e
		})
