import { axiosInstance } from 'shared/api/instance'
import { convertersLib, requestLib } from 'shared/lib'
import { ISearchGlobalResponse } from './types'

export interface ISearchGlobalParams {
	value: string
	onMap: 0 | 1
}

export const searchGlobal = (
	params: ISearchGlobalParams
): Promise<ISearchGlobalResponse> =>
	axiosInstance
		.post('SearchGloabal', requestLib.encodePostParams(params))
		.then((response) => response.data)
		.then((response) => response.SearchGloabal)
		.then((response) => {
			requestLib.throwResponseError(response)

			const globalSearchResult = response.globalSearch

			if (globalSearchResult.hasOwnProperty('coordinate')) {
				const coordinate = globalSearchResult['coordinate']
				const coords = coordinate.split(' ')

				return {
					type: 'coords',
					data: { coords },
				} as ISearchGlobalResponse
			}

			return {
				type: 'data',
				data: convertersLib.anyToArray(response?.globalSearch?.row),
			} as ISearchGlobalResponse
		})
		.catch((e) => {
			throw e
		})
