import { Stack } from '@mui/material'
import { IAttachment } from 'shared/api/documents'
import { DocListItem } from './doc-list-item'

interface IDocListProps {
	documents: IAttachment[]
	handleDocClick: (idDoc: string) => void
}
export const DocList: React.FC<IDocListProps> = ({
	documents,
	handleDocClick,
}) => {
	if (!documents.length) {
		return null
	}

	return (
		<Stack spacing={1}>
			{documents.map((doc) => (
				<DocListItem document={doc} handleOnClick={handleDocClick} />
			))}
		</Stack>
	)
}
