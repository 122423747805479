import { createContext, useContext } from 'react'

import type { Map, View } from 'ol'
import VectorSource from 'ol/source/Vector'
import { Coordinate } from 'ol/coordinate'
import { Extent } from 'ol/extent'

interface IMapContext {
	map: Map | null
	dataInfoSource: VectorSource
	dataGlobalInfoSource: VectorSource
	dataEditSource: VectorSource
	defaultZoom: number | undefined
	defaultExtent: Extent | undefined
}

const MapContext = createContext<IMapContext | null>(null)

export const MapContextProvider = MapContext.Provider

export const useMapContext = () => {
	const context = useContext(MapContext) as IMapContext

	if (context === null) {
		throw new Error("Don't provide map context")
	}

	return context
}
