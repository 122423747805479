import { Box, Typography } from '@mui/material'

import { useUserIsAuth } from 'entities/user'
import { useTranslate } from 'shared/i18n'

export const withAuth = (Component: new () => React.Component) => () => {
	const isAuth = useUserIsAuth()
	const { translate } = useTranslate()

	return !isAuth ? (
		<Box
			sx={{
				height: '100dvh',
				width: '100dvw',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography variant="body1" textAlign="center" whiteSpace="nowrap">
				{translate('This is a portal for displaying open data')}.
			</Typography>

			<Typography
				variant="body1"
				sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
			>
				{translate(
					"For correct display follow the link from the organization's website"
				)}
				.
			</Typography>
		</Box>
	) : (
		<Component />
	)
}
