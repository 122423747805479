import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Stack } from '@mui/material'

import { RequestStatus, useAppDispatch, useAppSelector } from 'shared/model'
import {
	IFetchSelectSearchResultParams,
	ISelectRecord,
	ISelectUserField,
} from 'shared/api/select'
import { selectApi } from 'shared/api'
import { convertersLib } from 'shared/lib'
import { LinerSpinner } from 'shared/ui'
import { useTranslate } from 'shared/i18n'

import { selectSelectors } from 'entities/select'
import { mapActions, mapSelectors } from 'widgets/map/model'

import { RecordData } from '../../map-data/record-data'

const getSelectResult = async (params: {
	page?: number
	sys?: string
	idSelect: number
}) => {
	const { page, sys, idSelect } = params

	const fetchParams: IFetchSelectSearchResultParams = {
		Select: String(idSelect),
	}

	if (page) {
		fetchParams.page = page
	}

	if (sys) {
		fetchParams.id = String(sys)
	}

	try {
		const { searchResult } = await selectApi.fetchSelectSearchResult(
			fetchParams
		)

		return searchResult
	} catch (e) {
		throw e
	}
}

export const SelectSysData: React.FC = React.memo(() => {
	console.log('render select sys data')
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()

	const geomsOnMap = useAppSelector((state) => state.map.infoMapGeoms)
	const idSelect = useAppSelector((state) => state.map.statIdSelect)
	const idLayer = useAppSelector(
		selectSelectors.selectIdLayerByIdSelect(idSelect)
	)
	const statActiveMarkerSysData = useAppSelector(
		(state) => state.map.statActiveMarkerSysData
	)
	const statSelectSysGeom = useAppSelector(
		(state) => state.map.statSelectSysGeom
	)

	const observer = useRef<IntersectionObserver | null>(null)
	const parentRef = useRef<HTMLDivElement | null>(null)
	const childRef = useRef<HTMLDivElement | null>(null)

	const [page, setPage] = useState(0)

	const [userFields, setUserFields] = useState<ISelectUserField[]>([])
	const [recordList, setRecordList] = useState<ISelectRecord[]>([])
	const [filteredRecordList, setFilteredRecordList] = useState<ISelectRecord[]>(
		[]
	)

	const [errorMsg, setErrorMsg] = useState<string | null>(null)
	const [status, setStatus] = useState<RequestStatus>(undefined)

	const fetchResult = useCallback(async () => {
		const nextPage = page + 1

		if (!idSelect || !nextPage) {
			return
		}

		setStatus('loading')
		setErrorMsg(null)

		try {
			const response = await getSelectResult({ page: nextPage, idSelect })
			setRecordList((prev) => [
				...prev,
				...response.filter(
					(i) =>
						!prev.find(
							(x) =>
								String(x.key) === String(i.key) &&
								String(x.map) === String(i.map) &&
								+x.idSelect === +i.idSelect
						)
				),
			])

			setPage(nextPage)
			setStatus('success')
		} catch (e) {
			setStatus('error')
			setErrorMsg(convertersLib.errorToString(e))
		}
	}, [page, idSelect])

	useEffect(() => {
		if (!idSelect) {
			return
		}

		;(async () => {
			await getInitData(idSelect)
		})()
	}, [idSelect])

	useEffect(() => {
		if (!childRef.current || !parentRef.current) {
			return
		}

		const options = {
			root: parentRef.current,
			rootMargin: '-10px',
			threshold: 0,
		}

		observer.current = new IntersectionObserver(([target]) => {
			if (target.intersectionRatio === 1 || !parentRef.current?.scrollTop) {
				return
			}

			if (target.isIntersecting) {
				if (status === 'loading') {
					return
				}

				fetchResult()
			}
		}, options)

		observer.current.observe(childRef.current)

		return function () {
			if (childRef.current && observer.current) {
				observer.current.unobserve(childRef.current)
				observer.current.disconnect()
			}
		}
	}, [childRef.current, parentRef.current, fetchResult, status])

	useEffect(() => {
		if (
			!idSelect ||
			statActiveMarkerSysData === null ||
			+statActiveMarkerSysData?.idSelect !== +idSelect
		) {
			setFilteredRecordList(recordList)
			return
		}

		const sysInRecordList = recordList.find(
			(i) =>
				String(i.map) === String(statActiveMarkerSysData.sys) &&
				+i.idSelect === +statActiveMarkerSysData.idSelect
		)

		if (!!sysInRecordList) {
			setFilteredRecordList([sysInRecordList])
			return
		}

		;(async () => {
			setStatus('loading')
			setErrorMsg(null)

			try {
				const response = await getSelectResult({
					sys: String(statActiveMarkerSysData.sys),
					idSelect,
				})

				setFilteredRecordList([])
				setRecordList((prev) => [...prev, ...response])
				setStatus('success')
			} catch (e) {
				setStatus('error')
				setErrorMsg(convertersLib.errorToString(e))
				console.log(e)
			}
		})()
	}, [statActiveMarkerSysData, recordList, idSelect])

	const geomOnMap = useCallback(
		(sys: string) => {
			return (
				!!geomsOnMap &&
				!!geomsOnMap.find(
					(i) => String(i.sys) === String(sys) && i.idSelect === idSelect
				)
			)
		},
		[geomsOnMap, idSelect]
	)

	const getGeom = useCallback(
		(sys: string) => {
			if (!statSelectSysGeom?.length) {
				return null
			}

			const geom = statSelectSysGeom?.find(
				(i) => String(i.sys) === String(sys)
			)?.geom

			return geom || ''
		},
		[statSelectSysGeom]
	)

	const getInitData = useCallback(
		async (idSelect: number) => {
			if (!idSelect) {
				return
			}

			setStatus('loading')
			setErrorMsg(null)

			try {
				const [recordData, userFieldsData] = await Promise.all([
					selectApi.fetchSelectSearchResult({
						Select: String(idSelect),
						page: 0,
					}),
					selectApi.fetchSelectUserFields({ idSelect: '' + idSelect }),
				])

				setRecordList(recordData.searchResult)
				setUserFields(userFieldsData.userFields)

				setStatus('success')
			} catch (e) {
				setStatus('error')
				setErrorMsg(convertersLib.errorToString(e))
			}
		},
		[idSelect]
	)

	const handleClearActiveSys = useCallback(() => {
		dispatch(mapActions.setInfoMapGeoms(null))
		dispatch(mapActions.setStatActiveMarkerSysData(null))
	}, [])

	const handleCloseSysMode = useCallback(() => {
		dispatch(mapActions.setStatActiveMarkerSysData(null))
		dispatch(mapActions.setStatIdSelect(null))
		dispatch(mapActions.setStatSelectSysGeom(null))
		dispatch(mapActions.setLeftSidebarContentType('home-screen'))
		dispatch(mapActions.setInfoMapGeoms(null))
	}, [])

	if (!idSelect || !idLayer) {
		return null
	}

	if (status === undefined) {
		return null
	}

	if (status === 'error') {
		return <div>{errorMsg}</div>
	}

	return (
		<Box
			sx={{
				height: '100%',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{ flexGrow: 1, overflowY: 'auto' }}
				className="scrollbar-hidden"
				ref={parentRef}
			>
				<Stack spacing={1.5}>
					{filteredRecordList.map((record) => (
						<RecordData
							key={record.key}
							record={record}
							userFields={userFields}
							idSelect={+idSelect}
							idLayer={+idLayer}
							sys={String(record.map)}
							geomOnMap={geomOnMap(String(record.map))}
							geom={getGeom(String(record.map))}
							defaultExpanded={filteredRecordList.length === 1}
							listenOnHover={true}
						/>
					))}
				</Stack>

				{status === 'loading' && <LinerSpinner />}

				<Box ref={childRef} sx={{ minHeight: '10px' }} />
			</Box>

			<Box sx={{ pt: 1.5 }}>
				{!!statActiveMarkerSysData &&
					filteredRecordList.length !== recordList.length && (
						<Button
							fullWidth
							variant="contained"
							onClick={handleClearActiveSys}
						>
							{translate('Back to list')}
						</Button>
					)}
				{(!statActiveMarkerSysData ||
					filteredRecordList.length === recordList.length) && (
					<Button fullWidth variant="contained" onClick={handleCloseSysMode}>
						{translate('Back to home screen')}
					</Button>
				)}
			</Box>
		</Box>
	)
})
