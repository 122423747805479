import { useMemo } from 'react'
import { Box, Divider, Stack } from '@mui/material'

import { useMapContext, mapLib } from 'widgets/map'

import { SwitchBaseLayers } from '../switch-base-layers'
import { SwitchOverlayLayers } from '../switch-overlay-layers'
import { useAppSelector } from 'shared/model'

export const LayerSwitcherWrapper = () => {
	const { map } = useMapContext()

	const isMapLoaded = useAppSelector((state) => state.map.mapOnLoadEnd)

	const overlayLayerList = useMemo(() => {
		if (!map || !isMapLoaded) {
			return []
		}

		return mapLib.getMapOverlayLyers({ map }).filter((i) => !i.get('autoload'))
	}, [map, isMapLoaded])

	const baseLayerList = useMemo(() => {
		if (!map || !isMapLoaded) {
			return []
		}

		return mapLib.getMapBaseLayers({ map })
	}, [map, isMapLoaded])

	if (!isMapLoaded) {
		return null
	}

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				rowGap: 2,
			}}
		>
			<Stack spacing={2} sx={{ flexGrow: 1, overflowY: 'auto' }}>
				<SwitchBaseLayers layerList={baseLayerList} />

				{!!baseLayerList.length && !!overlayLayerList.length && <Divider />}

				<SwitchOverlayLayers layerList={overlayLayerList} />
			</Stack>
		</Box>
	)
}
