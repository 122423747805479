import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ButtonGroup } from '@mui/material'

import { useAppDispatch, useAppSelector } from 'shared/model'

import {
	mapActions,
	mapApi,
	mapLib,
	mapSelectors,
	useMapContext,
} from 'widgets/map'

import type { DrawerModeType } from 'widgets/map/api'

import { PointDrawer } from './PointDrawer'
import { PolygonDrawer } from './PolygonDrawer'
import { LineDrawer } from './LineDrawer'
import { CloseDrawer } from './CloseDrawer'
import { SaveDrawer } from './SaveDrawer'
import { HoleDrawer } from './HoleDrawer'

import VectorSource from 'ol/source/Vector'
import VectorLayer from 'ol/layer/Vector'
import { Draw, Modify, Select, Snap, Translate } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { ModifyEvent } from 'ol/interaction/Modify'
import { Feature } from 'ol'
import { Geometry, LinearRing, Polygon } from 'ol/geom'
import { convertersLib } from 'shared/lib'

const source = new VectorSource()

const vector = new VectorLayer({
	source: source,
	zIndex: 9999,
	style: {
		'fill-color': 'rgba(255, 255, 255, 0.2)',
		'stroke-color': '#ffcc33',
		'stroke-width': 2,
		'circle-radius': 7,
		'circle-fill-color': '#ffcc33',
	},
})

const select = new Select()

const modify = new Modify({ source: source })

let intersected: Feature<Geometry> | null = null

const POLYGON_ID_LAYER = 10000
const LAYER_ID_LAYER = 6278
const POINT_ID_LAYER = 6243

export const Drawer = () => {
	const { map } = useMapContext()

	const dispatch = useAppDispatch()

	const mapModeType = useAppSelector(mapSelectors.selectMapModeType)

	const [drawerMode, setDrawerMode] = useState<DrawerModeType>(undefined)
	const [features, setFeatures] = useState<Feature[]>([])

	const drawRef = useRef<Draw | null>(null)
	const snapRef = useRef<Snap | null>(null)

	useEffect(() => {
		if (mapModeType === 'edit') {
			return
		}

		setDrawerMode(undefined)
	}, [mapModeType])

	useEffect(() => {
		if (!map) {
			return
		}

		map.addLayer(vector)

		const features = select.getFeatures()
		const translate = new Translate({
			features: features,
		})

		map.addInteraction(select)
		map.addInteraction(translate)
		map.addInteraction(modify)

		return () => {
			map.removeLayer(vector)
			map.removeInteraction(select)
			map.removeInteraction(translate)
			map.removeInteraction(modify)
		}
	}, [map])

	useEffect(() => {
		if (!map) {
			return
		}

		if (drawerMode !== 'Hole') {
			source.clear()
			setFeatures([])
		}

		drawRef.current && map.removeInteraction(drawRef.current)
		snapRef.current && map.removeInteraction(snapRef.current)

		drawRef.current = null
		snapRef.current = null

		if (drawerMode !== undefined) {
			initInteraction()
		}
	}, [map, drawerMode])

	useEffect(() => {
		const handleKeyDown = (evt: KeyboardEvent) => {
			evt.stopPropagation()

			if (evt.code === 'Escape') {
				setDrawerMode(undefined)
				dispatch(mapActions.setCurrentMapModeType(undefined))
			}
		}

		if (!map || drawerMode === undefined) {
			document.removeEventListener('keydown', handleKeyDown)
			return
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [map, drawerMode])

	const availableDrawHole = useMemo(() => {
		return !!features.filter((f) => {
			return f?.getGeometry()?.getType() === 'Polygon'
		}).length
	}, [features])

	const initInteraction = useCallback(() => {
		if (!map || !drawerMode) {
			return
		}

		if (!drawRef.current) {
			drawRef.current = new Draw({
				source: source,
				type: drawerMode === 'Hole' ? 'Polygon' : drawerMode,
			})

			map.addInteraction(drawRef.current)
		}

		if (!snapRef.current) {
			snapRef.current = new Snap({ source: source })
			map.addInteraction(snapRef.current)
		}

		drawRef.current.on('drawstart', (e) => {
			//source.clear()
			modify.setActive(false)

			if (drawerMode === 'Hole') {
				const extent = e?.feature?.getGeometry()?.getExtent()
				const source = vector.getSource()

				if (!extent || !source) {
					return
				}

				source.forEachFeatureIntersectingExtent(extent, (f) => {
					intersected = f
				})

				if (!intersected) {
					alert('No Feature Found to draw holes')
					e.target.abortDrawing()
					return
				}

				const coords = (intersected.getGeometry() as Polygon).getCoordinates()
				const coordsLength = coords.length

				e.feature.getGeometry()?.on('change', (e) => {
					let linear_ring = new LinearRing(e.target.getCoordinates()[0])
					let coordinates = (
						intersected?.getGeometry() as Polygon
					).getCoordinates()
					let geom = new Polygon(coordinates.slice(0, coordsLength))
					geom.appendLinearRing(linear_ring)
					intersected?.setGeometry(geom)
				})
			}
		})

		drawRef.current.on('drawend', (evt: DrawEvent) => {
			modify.setActive(true)

			modify.on('modifyend', (evt: ModifyEvent) => {
				console.log('modify end')
			})

			if (drawerMode === 'Hole') {
				setTimeout(() => {
					vector.getSource()?.removeFeature(evt.feature)
				}, 5)
				intersected = null
			}

			if (drawerMode !== 'Hole') {
				const feature = evt.feature
				setFeatures((prev) => [...prev, feature])
			}
		})
	}, [map, drawerMode])

	const handleOnDrawerIconClick = (drawerMode: DrawerModeType) => {
		setDrawerMode(drawerMode)
		if (drawerMode === undefined) {
			dispatch(mapActions.setCurrentMapModeType(undefined))
		} else {
			dispatch(mapActions.setCurrentMapModeType('edit'))
		}
	}

	const handleSaveClick = useCallback(async () => {
		if (!map) {
			return
		}

		let gml = ''
		let idLayerForSave = 0

		const filteredModeFeatures = features.filter(
			(f) =>
				f?.getGeometry()?.getType() ===
				(drawerMode === 'Hole' ? 'Polygon' : drawerMode)
		)

		const currentModeFeatures = filteredModeFeatures.map((f) => f.clone())

		currentModeFeatures.forEach((f) => {
			f?.getGeometry()?.transform('EPSG:3857', 'EPSG:4326')
		})

		if (drawerMode === 'Point') {
			gml = mapLib.pointGeom.gmlPointFromCoords(
				mapLib.pointGeom.coordsFromFeatures(currentModeFeatures)
			)

			idLayerForSave = POINT_ID_LAYER
		} else if (drawerMode === 'LineString') {
			gml = mapLib.lineGeom.gmlFromCoords(
				mapLib.lineGeom.coordsFromFeatures(currentModeFeatures)
			)

			idLayerForSave = LAYER_ID_LAYER
		} else if (drawerMode === 'Polygon' || drawerMode === 'Hole') {
			gml = mapLib.polygonGeom.gmlFromCoords(
				mapLib.polygonGeom.coordsFromFeatures(currentModeFeatures)
			)

			idLayerForSave = POLYGON_ID_LAYER
		} else {
			return
		}

		try {
			const response = await mapApi.editLayerElement({
				idLayer: idLayerForSave,
				geom: gml,
			})
		} catch (e) {
			alert(convertersLib.errorToString(e))
			console.log(e)
		} finally {
			const lyr = mapLib.getMapLayerById({ map, idLayer: idLayerForSave })

			if (lyr) {
				lyr.setVisible(true)
				const source = lyr.getSource()

				if (!source) {
					return
				}

				// @ts-ignore
				const params = source.getParams()

				if (!params) {
					return
				}

				params['updated'] = Date.now().toString()
				source.refresh()
			}

			dispatch(mapActions.setCurrentMapModeType(undefined))
		}
	}, [features, drawerMode, map])

	return (
		<ButtonGroup orientation="vertical">
			<PointDrawer
				isActive={drawerMode === 'Point'}
				cbcOnClick={handleOnDrawerIconClick}
			/>

			<LineDrawer
				cbcOnClick={handleOnDrawerIconClick}
				isActive={drawerMode === 'LineString'}
			/>

			<PolygonDrawer
				isActive={drawerMode === 'Polygon'}
				cbcOnClick={handleOnDrawerIconClick}
			/>

			{availableDrawHole && (
				<HoleDrawer
					isActive={drawerMode === 'Hole'}
					cbcOnClick={handleOnDrawerIconClick}
				/>
			)}

			{!!features.length && <SaveDrawer cbcOnClick={handleSaveClick} />}

			{drawerMode !== undefined && (
				<CloseDrawer cbcOnClick={handleOnDrawerIconClick} />
			)}
		</ButtonGroup>
	)
}
