import { useCallback, useEffect, useRef } from 'react'
import { useMapContext } from 'widgets/map/context'

export const OnResize = () => {
	const timer = useRef<NodeJS.Timer | null>(null)
	const { map } = useMapContext()

	useEffect(() => {
		if (!map) {
			return
		}

		map.on('movestart', (evt) => {
			console.log('movestart')
		})

		map.on('change:size', (evt) => {
			console.log('change view')
		})
	}, [map])

	return null
}
