import VectorSource from 'ol/source/Vector'

import { IUserBoundBox } from 'shared/api/user'

import { fromLonLat } from 'ol/proj'
import { Point } from 'ol/geom'
import { Feature, Map } from 'ol'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import VectorLayer from 'ol/layer/Vector'

export const moveToPoint = (params: {
	map: Map
	source: VectorSource
	coords: [number, number]
	boundBox?: IUserBoundBox
}) => {
	const { boundBox, coords, source, map } = params
	const lng = coords[0]
	const lat = coords[1]

	try {
		if (boundBox) {
			const { min: minBoundBox, max: maxBoundBox } = boundBox || {}

			if (minBoundBox && maxBoundBox) {
				const { lng: minLng, lat: minLat } = minBoundBox.Coordinate
				const { lng: maxLng, lat: maxLat } = maxBoundBox.Coordinate

				if (lng < minLng || lng > maxLng || lat < minLat || lat > maxLat) {
					throw new Error('You seem located outside the boundaries of the map')
				}
			}
		}

		const coordinates = fromLonLat([lng, lat])

		const markerGeometry = new Point(coordinates)
		const markerFeature = new Feature({
			geometry: markerGeometry,
		})

		const markerStyle = new Style({
			image: new CircleStyle({
				radius: 6,
				fill: new Fill({
					color: '#3399CC',
				}),
				stroke: new Stroke({
					color: '#fff',
					width: 2,
				}),
			}),
		})

		markerFeature.setStyle(markerStyle)

		source.addFeatures([markerFeature])

		const markerLayer = new VectorLayer({
			visible: true,
			source: source,
			zIndex: 9998,
		})

		markerLayer.setZIndex(9998)
		markerLayer.setVisible(true)

		map.addLayer(markerLayer)

		const geometry = source.getFeatures()[0]?.getGeometry()

		geometry &&
			// @ts-ignore
			map?.getView().fit(geometry, { maxZoom: 18, duration: 500 })
	} catch (e) {
		console.log('error:', { e, boundBox, coords })
		throw e
	}
}
