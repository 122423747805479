import { axiosInstance } from 'shared/api'
import { convertersLib, requestLib } from 'shared/lib'

export interface IFetchSelectSysGeometryParams {
	idLayer: number
	syss: string
}

export interface ISysGeom {
	sys: string
	geom: string
}

export const fetchSelectSysGeometry = (
	params: IFetchSelectSysGeometryParams
): Promise<ISysGeom[]> =>
	axiosInstance
		.post('GetGeometry', requestLib.encodePostParams(params))
		.then((response) => response.data)
		.then((response) => response.GetGeometry)
		.then((response) => {
			requestLib.throwResponseError(response)

			return convertersLib.anyToArray(response.row)
		})
		.catch((e) => {
			throw e
		})
