import SquareFootIcon from '@mui/icons-material/SquareFoot'

import { MapIconButton } from 'shared/ui/buttons'

import type { MeasureModeType } from 'widgets/map/api'
import { useTranslate } from 'shared/i18n'
import { BootstrapTooltip } from 'shared/ui'

interface IPolygonMeasureProps {
	cbcOnClick: (val: MeasureModeType) => void
	isActive: boolean
}

export const PolygonMeasure: React.FC<IPolygonMeasureProps> = ({
	isActive,
	cbcOnClick,
}) => {
	const { translate } = useTranslate()

	const handleClick = () => {
		cbcOnClick('Polygon')
	}

	return (
		<BootstrapTooltip title={translate('measuringArea')} placement="left">
			<MapIconButton disabled={isActive} onClick={handleClick}>
				<SquareFootIcon color={isActive ? 'info' : 'inherit'} />
			</MapIconButton>
		</BootstrapTooltip>
	)
}
