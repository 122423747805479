import React, { useEffect } from 'react'

import type { ILayer } from 'shared/api/user'

import { useMapContext, mapLib } from 'widgets/map'

import TileLayer from 'ol/layer/Tile'
import ImageLayer from 'ol/layer/Image'

export const InitAutoloadLayers: React.FC<{ layerList: ILayer[] }> = React.memo(
	({ layerList }) => {
		const { map } = useMapContext()

		useEffect(() => {
			if (!map || !layerList.length) {
				return
			}

			const mapLayerList: (TileLayer<any> | ImageLayer<any>)[] = []

			layerList.forEach((layer) => {
				const isBase = false

				let lyr: TileLayer<any> | ImageLayer<any>

				if (+layer.type === 3) {
					lyr = mapLib.createXYZTile(layer, isBase)
				} else if (!!layer.tiled) {
					lyr = mapLib.createTileLayer(layer, isBase)
				} else {
					lyr = mapLib.createImgLayer(layer, isBase)
				}

				lyr.setVisible(true)

				map.addLayer(lyr)

				mapLayerList.push(lyr)
			})

			return () => {
				mapLayerList.forEach((lyr) => {
					map.removeLayer(lyr)
				})
			}
		}, [map, layerList])

		return null
	}
)
