import {
	SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import _ from 'lodash'

import {
	Autocomplete,
	AutocompleteInputChangeReason,
	Button,
	Paper,
	Typography,
} from '@mui/material'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS, INPUT_BORDER_RADIUS } from 'shared/config'
import { theme } from 'shared/theme'
import { selectApi } from 'shared/api'
import { useTranslate } from 'shared/i18n'
import { convertersLib, parsersLib } from 'shared/lib'
import { Logo, MapTextField } from 'shared/ui'

import type { ISearchGlobalCoords, ISearchGlobalValue } from 'shared/api/select'
import { userSelectors } from 'entities/user'

import {
	mapActions,
	mapApi,
	mapLib,
	mapSelectors,
	useMapContext,
} from 'widgets/map'
import type { IMapInfoRowData } from 'widgets/map/api'

import { ClearData } from '../map-data'

export const MapSearch = () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()
	const { map, dataInfoSource } = useMapContext()
	const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)

	const inputRef = useRef<HTMLInputElement | null>(null)

	const [inputValue, setInputValue] = useState('')
	const [queryValue, setQueryValue] = useState('')
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState<ISearchGlobalValue[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [isCoords, setIsCoords] = useState(false)
	const [coords, setCoords] = useState<null | [number, number]>(null)

	const infoData = useAppSelector(mapSelectors.selectMapInfoData)
	const isOpenLeftSidebar = useAppSelector(mapSelectors.selectIsOpenLeftSidebar)
	const isOpenDrawer = useAppSelector(mapSelectors.selectIsOpenDrawer)
	const idActiveLayerList = useAppSelector(mapSelectors.selectActiveIdLayerList)
	const boundBox = useAppSelector(userSelectors.selectUserMapBoundBox)

	useEffect(() => {
		if (!open) {
			setOptions([])
			return
		}

		;(async () => {
			setIsLoading(true)
			setIsCoords(false)

			try {
				const response = await fetchData(queryValue)

				const { type, data } = response

				if (type === 'data') {
					setOptions(data as ISearchGlobalValue[])
				} else if (type === 'coords') {
					setCoords((data as ISearchGlobalCoords).coords)
				}
			} catch (e) {
				setOptions([])
				setCoords(null)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [open, queryValue])

	useEffect(() => {
		setIsCoords(!!coords?.length)
	}, [coords])

	const bgColor = useMemo(() => {
		return isOpenLeftSidebar || (isMobile && isOpenDrawer)
			? theme.palette.grey['200']
			: 'white'
	}, [isOpenLeftSidebar, isOpenDrawer, isMobile])

	const boxShadow = useMemo(() => {
		return !isMobile && !isOpenLeftSidebar
			? '0 2px 6px 0 rgba(0,0,0,0.2)'
			: 'none'
	}, [isOpenLeftSidebar, isMobile])

	const borderRadius = useMemo(() => {
		return isMobile ? '25px' : INPUT_BORDER_RADIUS
	}, [isMobile])

	const fetchData = (value: string) =>
		selectApi.searchGlobal({
			value,
			onMap: 1,
		})

	const handleInputChange = (
		evt: SyntheticEvent,
		value: string,
		reason: AutocompleteInputChangeReason
	) => {
		if (reason !== 'reset') {
			setInputValue(value)
		}
	}

	const handleKeyUp = () => {
		const val = inputRef.current?.value || ''
		setQueryValue(val)
	}

	const handleOnChange = async (value: ISearchGlobalValue | null) => {
		dataInfoSource.clear()

		if (!value) {
			return
		}

		try {
			const response = await mapApi.fetchGeom({
				idLayer: value.idLayer,
				syss: String(value.sys),
			})

			const infoData: IMapInfoRowData = {
				sys: String(value.sys),
				id: String(value.id),
				layerInfo: {
					layerID: value.idLayer,
				},
				selectInfo: {
					selectID: value.idSelect,
				},
				geom: response[0]?.geom || '',
				metafield: value.meta,
			}

			dispatch(mapActions.setMapinfoData([infoData]))
			dispatch(mapActions.setLeftSidebarContentType('map-data'))
			dispatch(mapActions.setIsOpenDrawer(true))
			dispatch(mapActions.setDrawerContentType('map-data'))
			dispatch(mapActions.setIsOpenLeftSidebar(true))

			setActiveLayer(value.idLayer)

			setInputValue('')
			setQueryValue('')
		} catch (e) {
			console.log(e)
		}
	}

	const setActiveLayer = useCallback(
		(idLayer: number) => {
			if (!idActiveLayerList.map((i) => +i).includes(idLayer)) {
				dispatch(
					mapActions.setActiveIdLayerList([...idActiveLayerList, idLayer])
				)
			}
		},
		[idActiveLayerList]
	)

	const handleGoToCoords = useCallback(async () => {
		dataInfoSource.clear()
		await dispatch(mapActions.setInfoMapGeoms(null))

		if (!map || !coords?.length) {
			return
		}

		try {
			mapLib.moveToPoint({
				map,
				coords: [coords[1], coords[0]],
				source: dataInfoSource,
				boundBox,
			})
		} catch (e) {
			const err = convertersLib.errorToString(e)
			alert(translate(err))
		}
	}, [map, coords, boundBox])

	return (
		<Autocomplete
			id="map-global-search"
			inputValue={inputValue}
			options={options}
			groupBy={(option) => option.nameSelect}
			getOptionLabel={(option) => option.meta}
			loading={isLoading}
			renderTags={() => null}
			renderInput={(params) => (
				<MapTextField
					{...params}
					inputRef={inputRef}
					bgColor={bgColor}
					borderRadius={borderRadius}
					placeholder={translate('Search')}
					size="small"
					sx={{
						boxShadow: boxShadow,
						transition: 'box-shadow .1s ease-out,background-color .1s ease-out',
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{infoData !== null ? <ClearData /> : null}
								{params.InputProps.endAdornment}
							</>
						),
						startAdornment: <Logo size="36px" mode="light" />,
					}}
				/>
			)}
			renderOption={(props, option, { inputValue }) => {
				return (
					<li {...props} key={option.id}>
						<Typography
							variant="body1"
							dangerouslySetInnerHTML={{
								__html: parsersLib.clearString(option.meta),
							}}
						/>
					</li>
				)
			}}
			onOpen={() => {
				setOpen(true)
				dispatch(mapActions.setIsOpenGlobalSearchList(true))
			}}
			onClose={() => {
				setOpen(false)
				dispatch(mapActions.setIsOpenGlobalSearchList(false))
			}}
			onInputChange={handleInputChange}
			onKeyUp={_.debounce(handleKeyUp, 500)}
			onChange={(_, value) => {
				handleOnChange(value)
			}}
			PaperComponent={(props) =>
				isCoords ? (
					<Paper sx={{ p: 1.5 }}>
						<Button
							onMouseDown={handleGoToCoords}
							variant="contained"
							fullWidth
						>
							{translate('goToCoordinates')}
						</Button>
					</Paper>
				) : (
					<Paper {...props} />
				)
			}
			clearOnBlur
			blurOnSelect
			disableClearable
			clearOnEscape
			disablePortal
			fullWidth
			noOptionsText={translate('no options')}
			loadingText={translate('loading')}
			filterOptions={(options) => options}
		/>
	)
}
