import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { BootstrapTooltip, MapIconButton } from 'shared/ui'
import { useTranslate } from 'shared/i18n'
import { useAppSelector } from '../../../../../shared/model'
import { userSelectors } from '../../../../../entities/user'

export const FaqAnchor = () => {
	const { translate } = useTranslate()
	const isAvailable = useAppSelector(userSelectors.getSettingById(7643))

	const handleOnClick = () => {
		try {
			window?.open('../gisbis/helpOpendata/index.htm', '_blank')?.focus()
		} catch (e) {
			console.log('error', e)
		}
	}

	if (!isAvailable) {
		return null
	}

	return (
		<BootstrapTooltip title={translate('FAQ')} placement="left">
			<MapIconButton onClick={handleOnClick}>
				<QuestionMarkIcon />
			</MapIconButton>
		</BootstrapTooltip>
	)
}
