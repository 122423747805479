import { Button, ButtonProps, styled } from '@mui/material'
import { BREAKPOINTS, BUTTON_BORDER_RADIUS } from 'shared/config'
import { useIsBreakpoint } from 'shared/hooks'
import React from 'react'

interface IExtendedButtonProps extends ButtonProps {
	ismobile: 'true' | 'false'
	background: string
}

const CustomButton = styled(Button)<IExtendedButtonProps>(
	({ theme, ismobile, background }) => ({
		'&:hover': {
			background: '#ffffff',
			color: theme.palette.primary.main,
			borderColor: theme.palette.divider,
			boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',
		},
		color: theme.palette.text.primary,
		minWidth: ismobile === 'true' ? '48px' : '40px',
		width: ismobile === 'true' ? '48px' : '40px',
		height: ismobile === 'true' ? '48px' : '40px',
		borderRadius: ismobile === 'true' ? '50%' : BUTTON_BORDER_RADIUS,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: background,
		borderColor: theme.palette.divider,
		boxShadow: '0 2px 6px 0 rgba(0,0,0,0.2)',
		transition: 'box-shadow .1s ease-out,background-color .1s ease-out',
		zIndex: 1,
	})
)

interface IMapIconButton extends ButtonProps {
	children: JSX.Element
	background?: string
}

export const MapIconButton: React.FC<IMapIconButton> = React.forwardRef(
	({ children, background = '#fff', ...props }, ref) => {
		const isMobile = useIsBreakpoint(BREAKPOINTS.mobile)

		return (
			<CustomButton
				{...props}
				background={background}
				ismobile={isMobile ? 'true' : 'false'}
				ref={ref}
			>
				{children}
			</CustomButton>
		)
	}
)
