import React, { useEffect } from 'react'

import type { ILayer } from 'shared/api/user'
import { useAppDispatch, useAppSelector } from 'shared/model'
import { userSelectors } from 'entities/user'
import { mapLib, useMapContext, mapActions } from 'widgets/map'

import TileLayer from 'ol/layer/Tile'
import ImageLayer from 'ol/layer/Image'
import { useTranslate } from 'shared/i18n'

export const InitBaseLayers: React.FC<{ layerList: ILayer[] }> = React.memo(
	({ layerList }) => {
		const { translate } = useTranslate()
		const { map } = useMapContext()
		const dispatch = useAppDispatch()

		const defaultBaseLayerId =
			useAppSelector(userSelectors.getSettingById(21)) || 'osm'

		useEffect(() => {
			if (!map || !layerList.length) {
				return
			}

			const mapLayerList: (TileLayer<any> | ImageLayer<any>)[] = []

			layerList.forEach((layer) => {
				const isBase = true

				let lyr: TileLayer<any> | ImageLayer<any>

				if (+layer.type === 3) {
					lyr = mapLib.createXYZTile(layer, isBase)
				} else if (!!layer.tiled) {
					lyr = mapLib.createTileLayer(layer, isBase)
				} else {
					lyr = mapLib.createImgLayer(layer, isBase)
				}

				map.addLayer(lyr)

				if (String(layer.id) === String(defaultBaseLayerId)) {
					lyr.set('title', translate('scheme'))

					lyr.set(
						'attr',
						'&copy;&nbsp;<a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
					)

					lyr.setVisible(true)

					dispatch(mapActions.setActiveIdBaseLayer(String(layer.id)))
				}

				mapLayerList.push(lyr)
			})

			return () => {
				mapLayerList.forEach((lyr) => {
					map.removeLayer(lyr)
				})
			}
		}, [map, layerList, defaultBaseLayerId])

		return null
	}
)
