import { useCallback, useMemo } from 'react'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { BootstrapTooltip, MapIconButton } from 'shared/ui'

import { mapActions, mapSelectors } from 'widgets/map'
import { useTranslate } from 'shared/i18n'

export const DesktopToggleSymbolList = () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()
	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)

	const isOpen = useAppSelector((state) => state.map.isOpenRightSidebar)

	const contentType = useAppSelector(
		(state) => state.map.rightSidebarContentType
	)

	const iconColor = useMemo(() => {
		return isOpen && contentType === 'symbol-list' ? 'info' : 'inherit'
	}, [isOpen, contentType])

	const handleOnClick = useCallback(() => {
		if (contentType === 'symbol-list') {
			dispatch(mapActions.setIsOpenRightSidebar(!isOpen))
			return
		}

		dispatch(mapActions.setRightSidebarContentType('symbol-list'))
		dispatch(mapActions.setIsOpenRightSidebar(true))
	}, [isOpen, contentType])

	return (
		<BootstrapTooltip
			placement="left"
			title={translate(isOpen ? 'close symbolsList' : 'open symbolsList')}
		>
			<span>
				<MapIconButton onClick={handleOnClick} disabled={!mapOnLoaded}>
					<LibraryBooksIcon color={iconColor} />
				</MapIconButton>
			</span>
		</BootstrapTooltip>
	)
}
