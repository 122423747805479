import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

import { useAppDispatch, useAppSelector } from 'shared/model'
import { BootstrapTooltip, MapIconButton } from 'shared/ui'
import { useIsBreakpoint } from 'shared/hooks'
import { BREAKPOINTS } from 'shared/config'

import { mapActions } from 'widgets/map'
import { selectDrawerData } from 'widgets/map/model/selectors'
import { useTranslate } from 'shared/i18n'
import { useMemo } from 'react'
import { theme } from 'shared/theme'

export const DesktopToggleLeftSidebar: React.FC = () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()

	const isOpen = useAppSelector((state) => state.map.isOpenLeftSidebar)

	const iconColor = useMemo(() => {
		return isOpen ? 'info' : 'inherit'
	}, [isOpen])

	const handleOnClick = () => {
		dispatch(mapActions.setIsOpenLeftSidebar(!isOpen))
	}

	return (
		<BootstrapTooltip
			placement="right"
			title={translate(isOpen ? 'close sidebar' : 'open sidebar')}
		>
			<MapIconButton
				onClick={handleOnClick}
				sx={{
					borderBottomLeftRadius: 0,
					borderTopLeftRadius: 0,
					borderTopRightRadius: '8px',
					borderBottomRightRadius: '8px',
					width: '30px',
					minWidth: '30px',
					height: '50px',
					minHeight: '50px',
				}}
			>
				{isOpen ? (
					<ArrowLeftIcon color={iconColor} fontSize="large" />
				) : (
					<ArrowRightIcon fontSize="large" />
				)}
			</MapIconButton>
		</BootstrapTooltip>
	)
}
