import { styled } from '@mui/material'

export const AutocompleteGroupHeader = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: '-8px',
	padding: '7.5px 10px',
	color: theme.palette.text.primary,
	fontWeight: 500,
	backgroundColor: theme.palette.grey.A100,
	borderTop: '1px solid #fff',
	zIndex: 10,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.grey.A200,
	},
}))
