import { useCallback, useMemo } from 'react'

import LayersIcon from '@mui/icons-material/Layers'

import { useAppSelector, useAppDispatch } from 'shared/model'
import { BootstrapTooltip, MapIconButton } from 'shared/ui'

import { mapActions, mapSelectors } from 'widgets/map'
import { useTranslate } from 'shared/i18n'

export const DesktopToggleLayerSwitcher = () => {
	const dispatch = useAppDispatch()
	const { translate } = useTranslate()

	const mapOnLoaded = useAppSelector(mapSelectors.selectMapOnLoadEnd)
	const isOpen = useAppSelector((state) => state.map.isOpenRightSidebar)

	const contentType = useAppSelector(
		(state) => state.map.rightSidebarContentType
	)

	const iconColor = useMemo(() => {
		return isOpen && contentType === 'layer-switcher' ? 'info' : 'inherit'
	}, [isOpen, contentType])

	const handleOnClick = useCallback(() => {
		if (contentType === 'layer-switcher') {
			dispatch(mapActions.setIsOpenRightSidebar(!isOpen))
			return
		}

		dispatch(mapActions.setRightSidebarContentType('layer-switcher'))
		dispatch(mapActions.setIsOpenRightSidebar(true))
	}, [isOpen, contentType])

	return (
		<BootstrapTooltip
			placement="left"
			title={translate(isOpen ? 'close layer switcher' : 'open layer switcher')}
		>
			<span>
				<MapIconButton onClick={handleOnClick} disabled={!mapOnLoaded}>
					<LayersIcon color={iconColor} />
				</MapIconButton>
			</span>
		</BootstrapTooltip>
	)
}
