import { useMapContext } from 'widgets/map/context'
import { useAppSelector } from 'shared/model'
import { useEffect } from 'react'
import { markerSelectStyle, markerStyle } from 'widgets/map/config'

export const ObserveSysMarkerHovered = () => {
	const { dataGlobalInfoSource } = useMapContext()

	const hoveredMarkerSysData = useAppSelector(
		(state) => state.map.statHoverMarkerSysData
	)

	useEffect(() => {
		const features = dataGlobalInfoSource.getFeatures()

		features.forEach((feat) => {
			const props = feat?.getProperties()[0]
			const featMarkerType = props?.['markerType']
			const featSys = props?.['sys']
			const featIdSelect = props?.['idSelect']

			if (featMarkerType !== 'mapInfoMarker') {
				return
			}

			if (!hoveredMarkerSysData) {
				feat.setStyle(markerStyle)
				return
			}

			if (
				+hoveredMarkerSysData.sys === +featSys &&
				+hoveredMarkerSysData.idSelect === +featIdSelect
			) {
				feat.setStyle(markerSelectStyle)
				return
			}

			feat.setStyle(markerStyle)
		})
	}, [hoveredMarkerSysData])

	return null
}
