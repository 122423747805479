import React, { useMemo } from 'react'

import { InitAutoloadLayers } from './init-autoload-layers'
import { InitCommonBaseLayers } from './init-common-base-layers'
import { InitBaseLayers } from './init-base-layers'
import { InitOverlayLayers } from './init-overlay-layers'
import { ILayer } from 'shared/api/user'
import { useAppSelector } from 'shared/model'
import { userSelectors } from 'entities/user'
import { mapLib } from 'widgets/map/index'

export const InitLayers: React.FC<{ layerList: ILayer[] }> = React.memo(
	({ layerList }) => {
		const idLayerScheme = useAppSelector(userSelectors.getSettingById(21))

		const baseLayerList = useMemo(() => {
			const list = mapLib.filterBaseLayers(layerList)

			if (idLayerScheme) {
				if (!list.find((i) => +i.id === +idLayerScheme)) {
					const schemeLayer = layerList.find((i) => +i.id === +idLayerScheme)
					schemeLayer && list.push(schemeLayer)
				}
			}

			return list
		}, [layerList, idLayerScheme])

		const overlayLayerList = useMemo(() => {
			let list = mapLib.filterOverlayLayers(layerList)

			if (idLayerScheme === undefined) {
				return list
			}

			return list.filter((i) => +i.id !== +idLayerScheme)
		}, [layerList, idLayerScheme])

		const autoloadLayerList = useMemo(() => {
			let list = mapLib.filterAutoloadLayers(layerList)

			if (idLayerScheme) {
				list = list.filter((i) => +i.id !== +idLayerScheme)
			}

			return list
		}, [layerList, idLayerScheme])

		return (
			<>
				<InitBaseLayers layerList={baseLayerList} />
				<InitCommonBaseLayers />
				<InitOverlayLayers layerList={overlayLayerList} />
				<InitAutoloadLayers layerList={autoloadLayerList} />
			</>
		)
	}
)
